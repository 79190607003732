/* eslint-disable default-param-last */
import * as actionTypes from "../../configs/constants/ActionTypes";
import { AFRICAN_COUNTRY_CODES } from "../../configs/constants/global";

const initState = {
  button: false,
  countries: [],
};

const common = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_BUTTON:
      return { button: action.data };

    case actionTypes.GET_COUNTRIES_SUCCESS: {
      const countries = [...action.data];
      const africanCountries = countries.filter((country) =>
        AFRICAN_COUNTRY_CODES.includes(country.code.toUpperCase()),
      );

      return {
        ...state,
        countries,
        africanCountries,
      };
    }
    case actionTypes.GET_COUNTRIES_FAILED:
      return {
        ...state,
        countries: [],
      };

    default:
      return state;
  }
};

export default common;
