import React from "react";
import { DropdownToggle } from "reactstrap";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Icon from "../../../components/Icon";

// import ArrowDown from "../../../components/svg/ArrowDown";

const GroupFilterToggle = ({ className, label }) => {
  return (
    <div className="d-flex align-items-center px-0 my-3">
      <Wrapper
        role="button"
        tag="div"
        className={`m-0 border-0 text-dark-blue sort d-flex btn justify-content-between align-items-center ${className}`}
      >
        <Icon c name="round-add" />

        <span className="cursor-pointer d-flex align-items-center" data-testid="toggle">
          <span className="ms-1 label">{label}</span>
        </span>
      </Wrapper>
    </div>
  );
};

const baseStyle = css`
  border-radius: 4px;
  border: 1px solid #e3e9f2;
  background: #fff;
  padding: 4px 5px;
  height: 32px;
`;

const Wrapper = styled(DropdownToggle)`
  ${baseStyle}
`;

GroupFilterToggle.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default GroupFilterToggle;
