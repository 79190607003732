import React from "react";

import InvoiceForm from "../../components/InvoiceForm";
import { createModal } from "../modals";
import {
  INVOICE_TYPE_COMMITMENT,
  INVOICE_TYPE_CREDIT_NOTE,
  INVOICE_TYPE_FINAL,
  INVOICE_TYPE_PURCHASE,
  INVOICE_TYPE_SALE,
  INVOICE_TYPES,
} from "../api";
import { cleanInvoiceFormInput, invoiceScope, InvoiceScope } from ".";
import { createInvoice, deleteInvoice, updateInvoice } from "../../redux/actions/InvoiceActions";
import store from "../../redux/store";
import AddPaymentForm from "../../components/AddPaymentForm";

/** Edit invoice */
export function onUpdateInvoice(invoice) {
  const { type } = invoice;
  const payouts = {};

  invoice?.line_items?.forEach((item) => {
    payouts[item.id] = { ...item, user: invoice.user };
  });

  const amount =
    invoice.type === INVOICE_TYPE_COMMITMENT || invoice.type === INVOICE_TYPE_CREDIT_NOTE
      ? invoice?.line_items?.[0]?.amount
      : invoice.amount;

  const modal = createModal({
    header: null,
    body: (
      <InvoiceForm
        id={`${type === INVOICE_TYPE_PURCHASE ? "payout" : "payment"}-form`}
        invoice={cleanInvoiceFormInput({
          type,
          commitment_amount:
            type === INVOICE_TYPE_FINAL && invoice.project.commitment_amount_balance
              ? invoice.project.commitment_amount_balance
              : 0,
          ...(invoice || {}),
          amount,
        })}
        payouts={payouts}
      />
    ),
    options: {
      title: `Edit ${INVOICE_TYPES[type]}`,
      className: "modal-payments modal-large",
      ok: `Update ${
        invoice.type === INVOICE_TYPE_PURCHASE ? "Payout" : INVOICE_TYPES[type] || "Payment"
      }`,
      form: {
        type: "submit",
        form: `${type === INVOICE_TYPE_PURCHASE ? "payout" : "payment"}-form`,
      },
    },
    proceed: (data) => {
      modal.setIsLoading(true);

      const feedbackCb = {
        successCb: () => modal.close(),
        failureCb: () => modal.setIsLoading(false),
      };

      const newData = { ...data };

      if (invoice.type === INVOICE_TYPE_FINAL) {
        delete newData.project;
      }

      if (
        [
          INVOICE_TYPE_SALE,
          INVOICE_TYPE_CREDIT_NOTE,
          INVOICE_TYPE_COMMITMENT,
          INVOICE_TYPE_FINAL,
        ].includes(type)
      ) {
        let line_items = Object.keys(newData?.payouts).map((key) => newData?.payouts[key]);

        if (data.type === INVOICE_TYPE_COMMITMENT) {
          line_items = [
            {
              amount: parseFloat(data.amount),
              description: "Commitment Payment",
              line_item_type: "Commitment Payment",
            },
          ];
        }

        if (data.type === INVOICE_TYPE_CREDIT_NOTE) {
          line_items = [
            {
              amount: parseFloat(data.amount),
              description: "Credit Note",
              line_item_type: "Credit Note",
            },
          ];
        }
        const payload = {
          ...newData,
          successMsg: "Invoice updated",
          action: "updated",
          line_items,
        };

        store.dispatch(updateInvoice(invoice.id, payload, feedbackCb));
      }
    },
  });
  modal.open();
}

/** Edit invoice batch */
export function onUpdateInvoiceBatch(invoices) {
  const invoice = invoices[0] || invoices;

  const cleanInvoice = {};
  ["type", "title", "issued_at", "milestone", "batch_ref"].forEach((key) => {
    cleanInvoice[key] = invoice[key];
  });

  const payouts = {};

  if (invoice?.invoices?.length > 1) {
    invoice?.invoices?.forEach((invoiceItem) => {
      if (invoiceItem?.line_items) {
        invoiceItem.line_items.forEach((item) => {
          payouts[item.id] = { ...item, user: invoiceItem.user };
        });
      }
    });
  } else {
    invoice?.line_items?.forEach((item) => {
      payouts[item.id] = { ...item, user: invoice.user };
    });
  }

  const modal = createModal({
    body: (
      <InvoiceForm
        id={`${invoice.type === INVOICE_TYPE_PURCHASE ? "payout" : "payment"}-form`}
        invoice={invoice}
        payouts={payouts}
        isEdit
      />
    ),
    header: null,
    canClose: true,
    options: {
      title: `Edit ${
        invoice.type === INVOICE_TYPE_PURCHASE ? "Payout" : INVOICE_TYPES[invoice.type] || "Payment"
      }`,
      className: "modal-payments modal-large",
      ok: `Update ${
        invoice.type === INVOICE_TYPE_PURCHASE ? "Payout" : INVOICE_TYPES[invoice.type] || "Payment"
      }`,
      form: {
        type: "submit",
        form: `${invoice.type === INVOICE_TYPE_PURCHASE ? "payout" : "payment"}-form`,
      },
    },
    proceed: (data) => {
      modal.setIsLoading(true);

      const feedbackCb = {
        successCb: () => modal.close(),
        failureCb: () => modal.setIsLoading(false),
      };

      if (invoice.type === INVOICE_TYPE_PURCHASE) {
        const cleanData = [];

        // eslint-disable-next-line no-shadow
        const { invoice, payouts } = data;
        const { Projects } = store.getState();

        if (payouts && invoice) {
          const userInvoices = {};
          Object.keys(payouts).forEach((idx) => {
            const payout = payouts[idx];
            if (payout.user && payout.amount) {
              const userId = payout.user.id;
              const invoiceItem = invoice?.invoices?.find((inv) => inv?.user?.id === userId);

              // If no invoice for the user exists, create a new one
              if (!userInvoices[userId]) {
                userInvoices[userId] = {
                  // ...invoice,
                  batch_ref: invoice.batch_ref,
                  type: invoice.type,
                  issued_at: invoice.issued_at,
                  title: invoice.title,
                  milestone: invoice.milestone ? { id: invoice.milestone.id } : null,
                  user: { id: userId },
                  amount: 0,
                  line_items: [], // Initialize line_items array
                  ...(invoiceItem?.id ? { id: invoiceItem.id } : { id: invoice.id }),
                  ...(Projects?.project?.id ? { project: { id: Projects.project.id } } : {}),
                };
              }

              // Add line_items details to the user's invoice
              userInvoices[userId].line_items.push({
                line_item_type: payout.line_item_type,
                description: payout.description,
                amount: parseFloat(payout.amount),
              });
            }
          });
          // eslint-disable-next-line no-unreachable
          Object.keys(userInvoices).forEach((userId) => {
            cleanData.push(userInvoices[userId]);
          });
        }

        const retainedIds = [];

        if (cleanData.length) {
          cleanData.forEach((item) => {
            if (item.id) {
              retainedIds.push(item.id);

              store.dispatch(
                updateInvoice(
                  item.id,
                  {
                    ...item,
                    action: "updated",
                    successMsg: "Invoice updated",
                  },
                  feedbackCb,
                ),
              );
            } else {
              store.dispatch(createInvoice(item, feedbackCb));
            }
          });
        }

        if (invoice?.invoices?.length > 1) {
          invoice?.invoices.forEach((item) => {
            if (!retainedIds.includes(item.id)) {
              store.dispatch(deleteInvoice(item));
            }
          });
        } else if (!retainedIds.includes(invoices.id)) {
          store.dispatch(deleteInvoice(invoices));
        }
      }
    },
  });
  modal.open();
}

/** Edit payment */
export function onUpdatePayment(invoice) {
  const payouts = {};

  invoice?.line_items?.forEach((item) => {
    payouts[item.id] = { ...item, user: invoice.user };
  });

  const modal = createModal({
    header: null,
    body: (
      <AddPaymentForm
        id="payment-form"
        invoice={cleanInvoiceFormInput({
          ...invoice,
        })}
        payouts={payouts}
        isEdit
      />
    ),
    options: {
      title: (
        <div className="d-flex align-items-center">
          Edit Payment{" "}
          {invoice.invoice_scope !== "single_developer" && (
            <InvoiceScope className="ms-1">{invoiceScope[invoice.invoice_scope]}</InvoiceScope>
          )}
        </div>
      ),
      className: "modal-payments modal-xlarge",
      ok: `Update Payment`,
      form: {
        type: "submit",
        form: "payment-form",
      },
      hideActions: false,
    },
    proceed: (data) => {
      modal.setIsLoading(true);

      const feedbackCb = {
        successCb: () => modal.close(),
        failureCb: () => modal.setIsLoading(false),
      };

      const line_items = Object.keys(data.payouts).map((key) => {
        const devData = data.payouts[key].developer;
        const developer = typeof devData === "number" ? devData : devData.id;

        return {
          ...data.payouts[key],
          developer,
        };
      });

      const payload = {
        ...data,
        successMsg: "Invoice updated",
        action: "updated",
        line_items,
      };

      store.dispatch(updateInvoice(invoice.id, payload, feedbackCb));
    },
  });
  modal.open();
}
