import { SEPA_COUNTRIES, emailSchema } from "../configs/constants/global";

export function displayExpectedReturn(value) {
  let displayValue = "permanent";
  switch (value) {
    case "2w":
      displayValue = "2 weeks";
      break;
    case "6m":
      displayValue = "6 months";
      break;
    default:
      break;
  }
  return displayValue;
}

/* Flatten arrays and objects to string */
export function flattenToString(data) {
  if (!data) return data;

  if (typeof data === "string") return data;

  if (Array.isArray(data)) return data.toString();

  let result = "";
  Object.keys(data).forEach((key, index) => {
    result += index === 0 ? data[key] : `, ${data[key].toString()}`;
  });
  return result;
}

/** Skills object to array */
export function skillsToArray(data) {
  if (!data) return [];

  let result = [];
  Object.keys(data).forEach((key) => {
    result = [...result, ...data[key]];
  });

  return result;
}

export function getValueByKeyPath(keyPath, object) {
  const keys = keyPath.split(".");
  let value = object;

  for (let i = 0; i < keys.length; i += 1) {
    const k = keys[i];
    if (!value[k]) {
      // If the property does not exist, create an empty object
      value[k] = {};
    }

    value = value[k];
  }

  return value;
}

export const constructURLQueryFromState = (state) => {
  const entries = Object.entries(state);

  const query = entries.flatMap(([key, value]) => {
    const keyMaps = {
      invoiceType: "type",
      invoiceStatus: "status",
      invoiceEmailStatus: "emailStatus",
      paymentPlatform: "payment-platform",
      ordering: "ordering",
    };

    if (key === "date") {
      return [
        ["from", value.from || ""],
        ["to", value.to || ""],
      ];
    }

    return [[keyMaps[key], [value.toString()]].flat(1)];
  });

  return query;
};

export const arrayToObject = (s) => {
  const x = {};

  s.forEach((item) => {
    Object.keys(item).forEach((key) => {
      x[key] = item[key];
    });
  });

  return x;
};

export const generateColumnsFromArray = (arr) => {
  const columns = {
    main: [],
    extra: [],
  };

  arr.forEach((item) => {
    const { Header, accessor, columnOpts } = item;
    const columnName = typeof Header === "string" && Header ? Header : accessor?.replace(/_/g, " ");

    if (columnOpts && Object.keys(columnOpts).length) {
      if (columnOpts.main) {
        columns.main.push({ name: columnName, editable: columnOpts.editable, visible: true });
      } else {
        columns.extra.push({ name: columnName, editable: columnOpts.editable, visible: true });
      }
    }
  });

  columns.main.sort((a, b) => a.editable - b.editable);
  columns.extra.sort((a, b) => a.editable - b.editable);

  return columns;
};

export const columnFilter =
  (columns) =>
  ({ Header, accessor }) => {
    const cols = Object.values(columns).flat(1);
    const col = cols.find(({ name }) => name === Header || name?.replace(" ", "_") === accessor);
    if (col) return col?.visible;
    return true;
  };

export const convertToAndString = (arr) => {
  if (arr.length === 0) {
    return "";
  }

  if (arr.length === 1) {
    return arr[0].toLowerCase();
  }
  const lastItem = arr.pop().toLowerCase();
  const formattedItems = arr.map((item) => item.toLowerCase()).join(", ");
  return `${formattedItems} and ${lastItem}`;
};

export const decodeHtmlEntities = (html) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = html;
  return textarea.value;
};

export const validateEmailString = async (email) => {
  try {
    await emailSchema.validate(email);
    return false;
  } catch (er) {
    return er.message;
  }
};

export const isSepaCountry = (iban) => {
  if (!iban) return false;

  const countryCode = iban.slice(0, 2).toUpperCase();
  return SEPA_COUNTRIES.includes(countryCode);
};

export const validateUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    // Check if URL has required components
    return parsedUrl.protocol && parsedUrl.hostname && parsedUrl.hostname.includes(".");
  } catch (err) {
    return false;
  }
};
