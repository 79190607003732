/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { FormGroup, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import moment from "moment";

import { StyledForm, StyledDateTimePicker } from "../../utils/styles";
import Input from "../Input";
import FieldError from "../FieldError";
import Icon from "../Icon";
import Select from "../Select";
import PaymentInvoiceItem from "./PaymentInvoiceItem";
import ProjectSelector from "../ProjectSelector";
import { cleanSkills as cleanProjects } from "../../utils/api";
import { isAdmin } from "../../utils/auth";

const getGrandTotal = (payouts) => {
  const sum = Object.values(payouts || {}).reduce((acc, { amount }) => {
    return acc + Math.floor(parseFloat(amount || 0));
  }, 0);

  return sum;
};

const AddNewPaymentForm = ({ id, invoice, payouts: initialPayouts, proceed, errors }) => {
  const { project, projects } = useSelector(({ Projects }) => Projects);

  const [state, setState] = useState(() => {
    return {
      invoice: invoice || {},
      payouts: initialPayouts || { 0: {} },
      error: null,
      errors: errors || null,
      grandTotal: getGrandTotal(initialPayouts),
    };
  });

  const currency = state?.invoice?.currency || "EUR";
  const participantsList = project?.participation || state.invoice?.project?.participation;
  const participants = participantsList?.map((participant) => participant.user);

  const onChangeValue = (key, value) => {
    setState((prev) => ({
      ...prev,
      invoice: { ...prev.invoice, [key]: value },
      errors: { ...prev.errors, [key]: null },
    }));
  };

  const onPayoutUpdate = (idx, key, value) => {
    const newPayout = {};
    const newState = {};

    newPayout[key] = value;
    newState[idx] = {
      ...(state.payouts[idx] || {}),
      ...newPayout,
    };

    setState((prev) => {
      const newPayouts = { ...prev.payouts, ...newState };
      const grandTotal = getGrandTotal(newPayouts);

      return { ...prev, payouts: newPayouts, grandTotal };
    });
  };

  const removePayoutByIndex = (index) => {
    setState((prevState) => {
      const updatedPayouts = { ...prevState.payouts };

      if (Object.prototype.hasOwnProperty.call(updatedPayouts, index)) {
        delete updatedPayouts[index];
      }

      const grandTotal = getGrandTotal(updatedPayouts);

      return {
        ...prevState,
        payouts: updatedPayouts,
        grandTotal,
      };
    });
  };

  const checkPaymentRequired = () => Object.keys(state.payouts).length > 0;

  const onChangeField = (e, key) => onChangeValue(key, e.target.value);

  const renderNewPayment = (idx) => {
    const payout = state.payouts[idx] || {};
    const amount = payout.amount || "";
    const description = payout.description || "";
    const lineItemType = payout.line_item_type || "";
    const developer =
      typeof payout.developer === "number"
        ? participants.find((p) => p.id === payout.developer)
        : payout.developer || {};

    const updatePayout = (key, value) => onPayoutUpdate(idx, key, value);
    const remove = () => removePayoutByIndex(idx);

    return (
      <PaymentInvoiceItem
        {...{
          idx,
          updatePayout,
          amount,
          description,
          lineItemType,
          currency,
          remove,
          participants,
          developer,
          required: checkPaymentRequired(),
        }}
      />
    );
  };

  const onSave = (e) => {
    e.preventDefault();
    if (proceed) {
      proceed({ ...state.invoice, payouts: state.payouts });
    }
  };

  const onAddPayout = () => {
    const { payouts } = state;
    const idx =
      Object.keys(payouts).length > 0 ? Math.max(...Object.keys(payouts).map(Number)) + 1 : 0;
    setState((prevState) => ({
      ...prevState,
      payouts: { ...prevState.payouts, [idx]: {} },
    }));
  };

  const filterProjects = (category) => {
    const filteredProjects = cleanProjects(projects).filter((proj) => proj.type === category);
    return filteredProjects;
  };

  return (
    <Container id={id} data-testid="invoice-form" onSubmit={onSave}>
      <Row>
        {!(project?.id || invoice?.id) ? (
          <>
            <Col md={6}>
              <FormGroup data-testid="projectInput">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                  Project
                  <LabelStyle>
                    <span className="label-style">*</span>
                  </LabelStyle>
                </label>
                {state.errors && state.errors.project_title_error && (
                  <FieldError message={state.errors.project_title_error} />
                )}

                <ProjectSelector
                  onChange={(title) => onChangeValue("projectTitle", title)}
                  handleProjectObj={(proj) => {
                    onChangeValue("project", proj);

                    // reset ref Invoice
                    setState((prev) => ({
                      ...prev,
                      invoice: {
                        ...prev.invoice,
                        ref_invoice: {},
                      },
                    }));
                  }}
                  value={state.projectTitle}
                  selected={filterProjects("project") || []}
                  placeholder="Choose project"
                  queryParams={{
                    stage: "active",
                    archived: "False",
                    show_all: isAdmin(),
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label htmlFor="payment-title">
                  Payment Title
                  <LabelStyle>
                    <span className="label-style">*</span>
                  </LabelStyle>
                </label>

                {state.errors && state.errors.title && <FieldError message={state.errors.title} />}
                <Input
                  id="payment-title"
                  value={state.invoice.title}
                  onChange={(e) => onChangeField(e, "title")}
                  placeholder="Enter a title for this payment"
                  dataTestId="paymentTitle"
                  required
                />
                <div className="text text-sm">Don&#39;t include project title here</div>
              </FormGroup>
            </Col>
          </>
        ) : (
          <Col md={12}>
            <FormGroup>
              <label htmlFor="payment-title">
                Payment Title
                <LabelStyle>
                  <span className="label-style">*</span>
                </LabelStyle>
              </label>

              {state.errors && state.errors.title && <FieldError message={state.errors.title} />}
              <Input
                id="payment-title"
                value={state.invoice.title}
                onChange={(e) => onChangeField(e, "title")}
                placeholder="Enter a title for this payment"
                dataTestId="paymentTitle"
                required
              />
              <div className="text text-sm">Don&#39;t include project title here</div>
            </FormGroup>
          </Col>
        )}
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <label htmlFor="invoice-date">Invoice Date</label>
            {state.errors && state.errors.issued_at && (
              <FieldError message={state.errors.issued_at} />
            )}
            <StyledDateTimePicker
              $calendar
              id="invoice-date"
              data-testid="invoice-date"
              className="tg-date-field"
              placeholder="Enter invoice date"
              format="DD MMM YYYY"
              $time={false}
              value={state.invoice.issued_at ? new Date(state.invoice.issued_at) : null}
              onChange={(issued_at) => {
                onChangeValue("issued_at", moment.utc(issued_at).format());
              }}
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <label htmlFor="payment-period" className="control-label">
              Payment Period (in days)
              <LabelStyle>
                <span className="label-style">*</span>
              </LabelStyle>
            </label>
            {state.errors && state.errors.payment_period && (
              <FieldError message={state.errors.payment_period} />
            )}
            <Select
              id="payment-period"
              data-testid="payment-period"
              onChange={(e) => onChangeField(e, "payment_period")}
              value={state.invoice.payment_period ? state.invoice.payment_period : "14"}
              required
            >
              <option value="7">7</option>
              <option value="14">14</option>
              <option value="21">21</option>
              <option value="30">30</option>
              <option value="60">60</option>
            </Select>
          </FormGroup>
        </Col>
      </Row>

      <div className="d-flex flex-column gap-3 items-container">
        {Object.keys(state.payouts).map((idx) => {
          return <Fragment key={idx}>{renderNewPayment(+idx)}</Fragment>;
        })}

        <div className="add-more">
          <button type="button" onClick={onAddPayout}>
            <Icon name="round-add" size="main" /> Add invoice item
          </button>
        </div>
      </div>

      <div className="grand-total_container mt-3">
        <div className="total-wrapper">
          <p className="m-0">Grand Total</p>
          <p className="m-0">
            {currency === "USD" ? "$" : "€"} {state?.grandTotal}
          </p>
        </div>
      </div>
    </Container>
  );
};

const Container = styled(StyledForm)`
  .items-container {
    max-height: 500px;
    overflow-y: scroll;
  }

  .grand-total_container,
  .total-wrapper {
    display: flex;
    align-items: center;
  }

  .grand-total_container {
    display: flex;
    align-items: center;
    justify-content: end;

    .total-wrapper {
      background-color: #f5f7fa;
      border: 1px solid #e3e9f2;
      border-radius: 4px;
      padding: 8px 10px;
      column-gap: 30px;
      min-width: 220px;
    }
  }
`;

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

export default AddNewPaymentForm;
