import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { debounce } from "lodash";
import axios from "axios";
import { useDispatch } from "react-redux";

import PoolTable from "./PoolTable";
import PoolFilters from "./PoolFilters";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import AdvancedFilterBar from "./AdvancedFilter";
import { FilterContext } from "../../../context/FilterContext";
import { listTalents } from "../../../redux/actions/UserActions";
import { isDev } from "../../../utils/auth";

const { CancelToken } = axios;
let cancel;

const TalentPool = () => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [page_size, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);

  const { totalFilters, filter, clearAllFilters } = useContext(FilterContext);

  const { open: openSideBar } = useRightNav();

  const path = window.location.pathname.split("/");
  const urlParamFilter = path[path.length - 1];

  const clearSearch = () => {
    setSearchTerm("");
    setSearchQuery("");
  };

  const fetchTalentList = useCallback(
    (skillsFilter) => {
      const nextPage = currentPage + 1;

      dispatch(
        listTalents(
          {
            page: nextPage,
            page_size,
            search: searchQuery,
            is_vetted: urlParamFilter === "vetted" || "",
            is_on_assignment: urlParamFilter === "on-assignment" || "",
            skills_years:
              skillsFilter && skillsFilter.filters.length > 0 ? JSON.stringify(skillsFilter) : "",
          },
          {
            cancelToken: new CancelToken((c) => {
              cancel = c;
            }),
          },
        ),
      );
    },
    [currentPage, page_size, searchQuery, urlParamFilter],
  );

  const handleSubmitSkillsFilter = useCallback(
    ({ reset, skillsFilter }) => {
      if (reset) {
        clearAllFilters();
      }

      fetchTalentList(skillsFilter);
    },
    [clearAllFilters, fetchTalentList],
  );

  const handleOpenFilters = () => {
    openSideBar(<AdvancedFilterBar onSubmit={handleSubmitSkillsFilter} />, {
      width: "480px",
    });
  };

  const delayedQuery = useMemo(() => debounce((q) => setSearchQuery(q), 500), []);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    delayedQuery(event.target.value);
  };

  useEffect(() => {
    if (cancel !== undefined) {
      cancel("aborted");
    }

    fetchTalentList();
  }, [fetchTalentList]);

  useEffect(() => {
    // Reset pagination on filter, searchQuery, skillsFilterchange
    setCurrentPage(0);
  }, [filter, searchQuery]);

  if (isDev()) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <PoolFilters
        links={[
          {
            route: "talent-pool/all",
            name: "All Developers",
          },
          {
            route: "talent-pool/vetted",
            name: "Vetted",
          },
          {
            route: "talent-pool/on-assignment",
            name: "On Assignment",
          },
        ]}
        totalFilters={totalFilters}
        searchTerm={searchTerm}
        handleOpenFilters={handleOpenFilters}
        handleChange={handleChange}
        clearSearch={clearSearch}
      />

      <Switch>
        <Route
          path="/talent-pool/:filter"
          render={({ match: { params } }) => {
            return (
              <PoolTable
                filter={params.filter}
                searchQuery={searchQuery}
                currentPage={currentPage}
                page_size={page_size}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
              />
            );
          }}
        />
        <Redirect from="*" to="/talent-pool/all" />
      </Switch>
    </>
  );
};

export default TalentPool;
