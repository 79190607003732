import React, { useContext, useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import styled, { withTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Icon from "../../../components/Icon";
import { StyledSearchInput } from "../../../utils/styles";
import IconButton from "../../../components/IconButton";
import { FilterContext } from "../../../context/FilterContext";
import { getCountries } from "../../../redux/actions/CommonActions";

const CountryFilterInput = ({ countryName, groupIndex, countryIndex }) => {
  const { deleteCountry, setCountryName } = useContext(FilterContext);
  const dispatch = useDispatch();

  const { africanCountries } = useSelector(({ Common }) => Common);

  const [openBtn, setOpenBtn] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const clearSearch = () => {
    setSearchTerm("");
    setCountryName(groupIndex, countryIndex, "");
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    setCountryName(groupIndex, countryIndex, value);
  };

  const onDeleteCountry = () => {
    deleteCountry(groupIndex, countryIndex);
  };

  const countryOptions = useMemo(() => {
    const options = [];

    options.push({ value: "", name: "-- Select a country --" });

    const filteredCountries = africanCountries?.filter((country) => {
      if (country.name) {
        const searchValue = searchTerm.toLowerCase();
        return country.name.toLowerCase().includes(searchValue);
      }
      return false;
    });

    const countriesWithCodes = filteredCountries?.reduce((accumulator, currentValue) => {
      if (currentValue.code) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

    if (countriesWithCodes && countriesWithCodes.length) {
      countriesWithCodes.forEach((country, idx) => {
        if (country.code || idx) {
          options.push({
            value: country.code,
            name: country.name === "The Netherlands" ? "Netherlands" : country.name,
          });
        }
      });
    }
    return options;
  }, [africanCountries, searchTerm]);

  useEffect(() => {
    if (!africanCountries || africanCountries.length === 0) {
      dispatch(getCountries());
    }
  }, [africanCountries]);

  return (
    <SelectedItem>
      <div className="actions">
        <StyledDropdown
          isOpen={openBtn}
          toggle={() => setOpenBtn(!openBtn)}
          data-testid="countryFilterWrapper"
        >
          <DropdownToggle className="w-100" tag="div">
            <StyledInput
              type="text"
              key={countryIndex}
              value={countryName}
              onChange={handleChange}
            />
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu">
            <div className="">
              <StyledSearchInput
                key={countryIndex}
                data-testid="country-search"
                type="search"
                value={countryName}
                onChange={handleChange}
                autoComplete="off"
                placeholder="Search Country"
                prepend={<Icon name="search1" />}
                isAppendText={false}
                append={null}
                appendFunc={clearSearch}
              />
            </div>

            {(countryOptions ?? []).map(({ name, slug }) => (
              <DropdownItem
                key={slug}
                className="dropdown-item add-btn"
                onClick={() => {
                  setSearchTerm("");
                  setCountryName(groupIndex, countryIndex, name);
                }}
                data-testid="drpdwn-country"
              >
                {name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </StyledDropdown>

        <IconButton
          name="delete-outline"
          size="sm"
          className="p-0 delete-button"
          data-testid="delete-country"
          onClick={onDeleteCountry}
        />
      </div>
    </SelectedItem>
  );
};

const StyledInput = styled(Input)`
  border-radius: 4px;
  border: 1px solid rgba(194, 204, 217, 0.25);
  background: #fff;
  box-shadow: none;
  background-color: #fff;
  font-size: 16px;
`;

const SelectedItem = withTheme(styled.div`
  color: ${(props) => props.theme.colors["dark-blue"]};
  justify-content: space-between;

  button.delete-button {
    height: auto;
    vertical-align: baseline;
    line-height: unset;

    i {
      font-size: ${(props) => props.theme.functions.pxToRem(18)};
      margin-left: 14px;
      color: ${(props) => props.theme.colors.gray};
    }

    &:disabled {
      i {
        opacity: 0.3;
      }
    }
  }

  > span {
    display: inline-block;
    place-self: center;
    padding: 8px 12px;
    border-radius: 50px;
    background: rgba(6, 46, 100, 0.04);
    border: 1px solid rgba(6, 46, 100, 0.04);
    margin-right: 16px;
  }

  select {
    box-shadow: none;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e3e9f2;
    background: #fff;
    font-size: 11px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    > {
      & * {
        padding-left: 5px;
        padding-right: 5px;
      }

      div {
        width: 100%;
      }
    }

    .border-zero {
      border: 0 !important;
      color: #062e64;
      outline: none !important;
      width: 36px;
    }

    button.btn {
      &.disable {
        opacity: 0.3;
        cursor: default;
      }
      cursor: pointer;

      span {
        display: none;
        color: ${(props) => props.theme.colors["dark-blue"]};
      }
    }
  }

  &:hover {
    .actions {
      button.btn {
        &.disable span {
          display: none;
        }
        span {
          display: inline-block;
        }
      }
    }
  }

  button {
    height: auto;
    vertical-align: baseline;
    line-height: unset;

    i {
      font-size: ${(props) => props.theme.functions.pxToRem(20)};
      margin-left: 14px;
      color: ${(props) => props.theme.colors["dark-blue"]};
    }

    &:disabled {
      i {
        opacity: 0.3;
      }
    }
  }
`);

const StyledDropdown = styled(Dropdown)`
  .dropdown {
    &-toggle {
      box-shadow: none;
      background: rgba(143, 155, 179, 0.05) !important;
      border-radius: 4px;
      color: #062e64 !important;
      padding: 8px 11px;
      display: flex;
      align-items: center;
      height: 40px;
      border: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;

      ::after {
        display: none;
      }

      :focus,
      :active,
      :hover {
        background: rgba(143, 155, 179, 0.05);
        color: #062e64;
      }
    }

    &-menu {
      max-height: 418px;
      overflow-y: scroll;

      &.show {
        top: 5px !important;
        background: #ffffff;
        border: 1px solid #e3e9f2;
        box-shadow: 0px 4px 20px rgba(21, 26, 48, 0.15);
        border-radius: 4px;
        min-width: 200px;
      }
    }

    &-item {
      padding: 5px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      &.view-all {
        border-bottom: 1px solid #edf1f7;
      }
    }
  }

  .dropdown-input {
    border-radius: 4px;
    border: 1px solid rgba(194, 204, 217, 0.25);
    background: #fff;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

CountryFilterInput.propTypes = {
  countryName: PropTypes.string,
  groupIndex: PropTypes.number,
  countryIndex: PropTypes.number,
};
export default CountryFilterInput;
