import { useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

const useDropdownPopper = (rootId = "#table-dropdown") => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const dropDownPortal = (element) => createPortal(element, document.querySelector(rootId));

  return {
    styles,
    attributes,
    setPopperElement,
    setReferenceElement,
    dropDownPortal,
  };
};

export default useDropdownPopper;
