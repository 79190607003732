import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

export const FilterContext = createContext();

const defaultState = {
  groups: [
    {
      type: "skills",
      groupOperation: "AND",
      skills: [],
    },
  ],
  searchOperation: "AND",
};

export const FilterProvider = ({ children, state = null }) => {
  const [filter, setFilter] = useState(state || defaultState);

  React.useEffect(() => {
    return () => {
      setFilter(defaultState);
    };
  }, []);

  const addFilterItem = (groupIndex, type) => {
    const updatedFilter = { ...filter };

    const defaultItem =
      type === "skills"
        ? {
            name: "",
            years: 0,
            operation: "gte",
          }
        : {
            name: "",
          };

    if (type === "skills") {
      if (!updatedFilter.groups[groupIndex].skills) {
        updatedFilter.groups[groupIndex].skills = [];
      }
      updatedFilter.groups[groupIndex].skills.push(defaultItem);
    } else if (type === "country") {
      if (!updatedFilter.groups[groupIndex].countries) {
        updatedFilter.groups[groupIndex].countries = [];
      }
      updatedFilter.groups[groupIndex].countries.push(defaultItem);
    }

    setFilter(updatedFilter);
  };

  const updateFilterItem = (groupIndex, skillIndex, skill) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].skills[skillIndex] = skill;
    setFilter(updatedFilter);
  };

  const deleteFilterItem = (groupIndex, skillIndex) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].skills.splice(skillIndex, 1);
    setFilter(updatedFilter);
  };

  const updateSearchOperation = (operation) => {
    const updatedFilter = { ...filter };
    updatedFilter.searchOperation = operation;
    setFilter(updatedFilter);
  };

  const updateGroupOperation = (groupIndex, operation) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].groupOperation = operation;
    setFilter(updatedFilter);
  };

  const addFilterGroup = (type) => {
    const updatedFilter = { ...filter };
    const filterObj =
      type === "skills"
        ? {
            type: "skills",
            groupOperation: "AND",
            skills: [],
          }
        : {
            type: "country",
            groupOperation: "AND",
            countries: [],
          };

    updatedFilter.groups.push(filterObj);
    setFilter(updatedFilter);
  };

  const deleteGroup = (groupIndex) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups.splice(groupIndex, 1);
    setFilter(updatedFilter);
  };

  const deleteSkill = (groupIndex, skillIndex) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].skills.splice(skillIndex, 1);
    setFilter(updatedFilter);
  };

  const deleteCountry = (groupIndex, countryIndex) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].countries.splice(countryIndex, 1);
    setFilter(updatedFilter);
  };

  const setSkillOperation = (groupIndex, skillIndex, operation) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].skills[skillIndex].operation = operation;
    setFilter(updatedFilter);
  };

  const setSkillYears = (groupIndex, skillIndex, years) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].skills[skillIndex].years = years;
    setFilter(updatedFilter);
  };

  const setSkillName = (groupIndex, skillIndex, name) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].skills[skillIndex].name = name;
    setFilter(updatedFilter);
  };

  const setCountryName = (groupIndex, countryIndex, name) => {
    const updatedFilter = { ...filter };
    updatedFilter.groups[groupIndex].countries[countryIndex].name = name;
    setFilter(updatedFilter);
  };

  const clearAllFilters = () => {
    setFilter({
      groups: [],
      searchOperation: "AND",
    });
  };

  const totalFilters = filter.groups.reduce((acc, group) => {
    if (group.type === "skills") return acc + group.skills.length;

    return acc + group.countries.length;
  }, 0);

  const contextValue = useMemo(() => {
    return {
      filter,
      totalFilters,
      addFilterItem,
      updateFilterItem,
      deleteFilterItem,
      updateSearchOperation,
      addFilterGroup,
      updateGroupOperation,
      deleteGroup,
      deleteSkill,
      deleteCountry,
      setSkillOperation,
      setSkillYears,
      setSkillName,
      setCountryName,
      clearAllFilters,
    };
  }, [filter, totalFilters]);

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
  state: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        groupOperation: PropTypes.string,
        skills: PropTypes.arrayOf({
          name: PropTypes.string,
          years: PropTypes.number,
        }),
      }),
    ),
  }),
};
