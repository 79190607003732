import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { ButtonDropdown, DropdownItem } from "reactstrap";
import PropTypes from "prop-types";
import Button from "../../../components/Button";
import IconButton from "../../../components/IconButton";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import { FilterContext } from "../../../context/FilterContext";
import { MenuV2 } from "../../../components/InvoiceFilters/Menu";
import GroupFilterToggle from "./GroupFilterToggle";
import SkillsFilterGroup from "./SkillsFilterGroup";
import CountryFilterGroup from "./CountryFilterGroup";

const selectOptions = [
  { value: "AND", name: "All" },
  { value: "OR", name: "Any" },
];

const filterGroupOptions = [
  { label: "By Skills", value: "skills" },
  { label: "By Country", value: "country" },
];

const FilterBar = ({ onSubmit }) => {
  const [dropdownOpen, setDropdown] = useState(false);

  const { close } = useRightNav();
  const { filter, updateSearchOperation, addFilterGroup, totalFilters } = useContext(FilterContext);
  const { groups, searchOperation } = filter;

  const toggle = () => setDropdown(!dropdownOpen);

  const skillsFilter = useMemo(() => {
    const operation = filter.searchOperation;
    const skillGroups = filter.groups
      .map((group) => {
        const { groupOperation, skills, countries, type } = group;
        const skillGroup = {
          group_operation: groupOperation,
        };

        if (type === "skills") {
          skillGroup.skills = skills.map((skill) => {
            return `${skill.name},${skill.operation},${skill.years}`;
          });
        }

        if (type === "country") {
          skillGroup.countries = countries.map((country) => {
            return country.name;
          });
        }

        return skillGroup;
      })
      .filter(Boolean);

    return {
      operation,
      filters: skillGroups,
    };
  }, [filter]);

  const handleClearAllFilters = (e) => {
    e.preventDefault();
    onSubmit({ reset: true });
    close();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ skillsFilter });
    close();
  };

  return (
    <FilterBarWrapper data-testid="filter-component">
      <nav>
        <IconButton
          className="btn-no-outline close-ic-btn"
          size="xs"
          name="angle-right-circle"
          onClick={() => close()}
        />
      </nav>

      <div className="nav-title">Filter</div>

      <div className="d-flex pb-3 justify-content-between" data-testid="filter-operation-div">
        <div>
          Match{" "}
          <select
            style={{ width: "auto" }}
            value={searchOperation}
            data-testid="search-operation"
            onChange={(ev) => updateSearchOperation(ev.target.value)}
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>{" "}
          filter groups
        </div>

        {(totalFilters > 0 || groups?.length > 0) && (
          <Button
            type="button"
            className="text-dark-blue mt-n3 clear-btn"
            role="link"
            onClick={handleClearAllFilters}
          >
            Clear all filters
          </Button>
        )}
      </div>

      {groups.map((group, groupIndex) => {
        if (group.type === "skills") return <SkillsFilterGroup {...{ groupIndex, group }} />;

        return <CountryFilterGroup {...{ groupIndex, group }} />;
      })}

      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        {/* Hide the dropdown if all types are already selected */}
        {filterGroupOptions.filter(({ value }) => !groups.some((group) => group.type === value))
          .length > 0 && (
          <>
            <GroupFilterToggle label="Add filter group" />

            <MenuV2 minwidth="180px">
              {filterGroupOptions
                .filter(({ value }) => !groups.some((group) => group.type === value)) // Exclude existing types
                .map(({ label, value }, idx) => (
                  <DropdownItem
                    key={idx + label}
                    className="dropdown-item add-btn"
                    onClick={() => {
                      addFilterGroup(value);
                    }}
                    data-testid={label}
                  >
                    {label}
                  </DropdownItem>
                ))}
            </MenuV2>
          </>
        )}
      </ButtonDropdown>

      <br />

      <Button
        type="submit"
        className="add-button"
        data-testid="apply-filter"
        disabled={totalFilters === 0}
        onClick={handleSubmit}
      >
        Apply filter
      </Button>
    </FilterBarWrapper>
  );
};

FilterBar.propTypes = {
  onSubmit: PropTypes.func,
};
const FilterBarWrapper = styled.div`
  padding: 24px;

  .close-ic-btn {
    font-size: 20px;
    display: contents;
    color: #8f9bb3;
  }

  .nav-title {
    padding: 24px 0;
    color: var(--gray-01, #151a30);
    font-size: 18px;
    font-weight: 500;
  }

  .add-button {
    border-radius: 4px;
    background: #da3451;
    color: #fff;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    height: 40px;
    font-weight: 500;
  }

  .clear-btn {
    margin-top: -10px;
  }
  select {
    box-shadow: none;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e3e9f2;
    background: #fff;
    font-size: 11px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

export default FilterBar;
