import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import styled, { withTheme, ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import InputGroup from "../../../../components/InputGroup";
import FieldError from "../../../../components/FieldError";
import { listAssessment } from "../../../../redux/actions/SkillAction";
import DeleteIcon from "../../../../assets/images/icons/x-circle-white.svg";
import { StyledForm } from "../../../../utils/styles";
import theme from "../../../../assets/theme";

const RequestAssessmentForm = ({ id, proceed, noIQTest }) => {
  const dispatch = useDispatch();

  const { assessments } = useSelector((state) => state.Skill);

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedSkills?.length === 0) return;

    const skills = selectedSkills?.map(({ id: skillId }) => skillId);

    proceed({ skills, ...(noIQTest ? { iq: "IQ Test" } : {}) });
  };

  const onSelectSkill = (skill) => (e) => {
    e.preventDefault();

    if (selectedSkills?.length === 3) return;

    setSelectedSkills((prev) => {
      const newSkill = [...prev];

      newSkill.push(skill);
      return newSkill;
    });
  };

  const deleteSelectedSkill = (item) => (e) => {
    e.preventDefault();

    setSelectedSkills((prev) => {
      const newSkill = [...prev].filter((skill) => skill.id !== item.id);
      return newSkill;
    });
  };

  const filteredSkills =
    assessments?.results?.filter(
      (skill) =>
        skill.name.toLowerCase().includes(searchTerm.toLowerCase()) && // Filter by search term
        selectedSkills.every((selected) => skill.id !== selected.id), // Exclude already selected skills
    ) || [];

  useEffect(() => {
    dispatch(listAssessment());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StyledForm
        style={{ minHeight: "250px" }}
        id={id}
        data-testid="test-request-form"
        onSubmit={handleSubmit}
      >
        <p className="subtitle">Search or select from the assessments below</p>
        <StyledFormGroup className="position-relative">
          <InputGroup
            placeholder="Type to search and select a skill..."
            selected={selectedSkills}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div className="skills-list mt-4 d-flex flex-wrap gap-3">
            {filteredSkills.length > 0 ? (
              filteredSkills.map((skill) => (
                <SkillItem
                  key={skill.id}
                  type="button"
                  className="d-inline-flex align-items-center opacity"
                  onClick={onSelectSkill(skill)}
                >
                  {skill.name}
                </SkillItem>
              ))
            ) : (
              <FieldError message="This skill does not have an assessment link available. Please check back later or contact support for assistance." />
            )}
          </div>

          {(selectedSkills?.length !== 0 || noIQTest) && (
            <div className="mt-4">
              <p className="mb-2 no-of-selected">{selectedSkills?.length || 0}/3 selected</p>

              <div className="mt-4 d-flex gap-3">
                {noIQTest && (
                  <SkillWrapper className="d-flex justify-content-between">
                    <SkillItem className="d-inline-flex align-items-center gap-1 selected">
                      IQ
                    </SkillItem>
                  </SkillWrapper>
                )}

                {selectedSkills.map((skill, idx) => (
                  <SkillWrapper key={`${skill}-${idx}`} className="d-flex justify-content-between">
                    <SkillItem
                      type="button"
                      className="d-inline-flex align-items-center gap-1 selected"
                      onClick={deleteSelectedSkill(skill)}
                    >
                      <span>{skill.name}</span>

                      <img width={24} height={24} src={DeleteIcon} alt="" />
                    </SkillItem>
                  </SkillWrapper>
                ))}
              </div>
            </div>
          )}
        </StyledFormGroup>
      </StyledForm>
    </ThemeProvider>
  );
};

const StyledFormGroup = styled(FormGroup)`
  .skills-list {
    max-height: 300px;
    overflow-y: scroll;
  }
`;

const SkillWrapper = styled.div`
  button {
    height: unset !important;
    vertical-align: baseline;
    line-height: unset !important;
    margin-left: 10px;
    border: none;
    background-color: transparent;

    &:disabled {
      opacity: 0.3;
    }
  }
`;

const SkillItem = withTheme(styled.button`
  padding: 3px 10px 4px;
  border-radius: 50px;
  border: 1px solid #3a4047;
  background-color: #fff;
  /* background: rgba(6, 46, 100, 0.15); */
  font-weight: 500;
  height: 30px;
  font-size: ${(props) => props.theme.functions.pxToRem(14)};
  line-height: ${(props) => props.theme.functions.pxToRem(21)};
  color: #3a4047;

  &.selected {
    color: #fff;
    border-color: ${(props) => props.theme.colors["dark-blue"]};
    background: ${(props) => props.theme.colors["dark-blue"]};

    svg path {
      stroke: #fff;
    }
  }
`);

RequestAssessmentForm.propTypes = {
  proceed: PropTypes.func,
  id: PropTypes.string,
  noIQTest: PropTypes.bool,
};

export default RequestAssessmentForm;
