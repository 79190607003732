import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import ContactInfo from "./ContactInfo";
import ReactTable from "../../../components/ReactTable";
import Avatar from "../../../components/Avatar";
import MetaTags from "../../../components/MetaTags";
import UserProfile from "../projects/components/UserProfile";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import { CaretDown, SyncRecruitee } from "../dashboard/Icons";
import { generateUserInitials } from "../../../utils/stringUtils";
import { trackEvent } from "../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../analytics/events";
import { createModal } from "../../../utils/modals";
import { devProfile } from "../../../redux/actions/ProjectActions";

const filters = {
  all: "Talent Pool - All Developers",
  vetted: "Talent Pool - Vetted Developers",
  "on-assignment": "Talent Pool - On Assignment",
};

const PoolTable = ({ filter, currentPage, page_size, setPageSize, setCurrentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { open: openSideBar, close: closeSideBar } = useRightNav();
  const { devProfiles } = useSelector(({ Projects }) => Projects);

  const { isFetching, talentPool } = useSelector(({ User }) => User);
  const { count, results } = talentPool;

  const [refreshingProfileId, setrefreshingProfileId] = useState(null);

  const eventProperties = {
    event_category: CATEGORIES.talentPool,
  };

  const tableData = useCallback(
    (data) => [
      ...data.map((dev) => {
        const row = {
          batch_action: dev,
          user: dev,
          status: dev.is_on_assignment,
          joined: dev.date_joined,
          actions: dev,
        };
        return row;
      }),
    ],
    [],
  );

  const handleResyncProfile = useCallback((id) => {
    setrefreshingProfileId(id);
    dispatch(devProfile(id, true));
  }, []);

  const handleUserContactInfo = useCallback((userDetails) => {
    const modal = createModal({
      body: <ContactInfo userDetails={userDetails} />,
      options: {
        title: `${userDetails?.first_name}’s contact info`,
        hideActions: true,
      },
    });
    modal.open();
  }, []);

  const handleUserProfile = useCallback(
    (id) => {
      openSideBar(
        <UserProfile userId={id} />,
        { width: "75%", scrollBehavior: "smooth" },
        true,
        true,
      );
    },
    [openSideBar],
  );

  const onLoadMore = useCallback((page, pageSize) => {
    localStorage.setItem("poolCurrentPage", page);
    setCurrentPage(page);
    setPageSize(pageSize);
  }, []);

  const getTableDisplayValue = (cell) => {
    const data = cell.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    switch (cell.column.id) {
      case "user": {
        return (
          <td className="nowrap user-row" key={`developer${key}`} style={{ width: "38%" }}>
            <div className="d-flex align-items-center">
              <Avatar image={data?.avatar_url} initials={generateUserInitials(data)} size="dash" />
              <button
                style={{
                  marginLeft: "-8px",
                  background: "none",
                  border: "none",
                }}
                type="button"
                onClick={() => {
                  trackEvent(EVENTS.view_dev_profile, {
                    ...eventProperties,
                    active_tab: "talent pool",
                    dev_name: data.display_name,
                    dev_id: data.id,
                  });
                  history.push(`/talent-pool/talent/${data.id}`);
                  closeSideBar();
                }}
              >
                <span
                  style={{ color: "black", padding: 6, fontWeight: "bold" }}
                  data-testid={`user_display_${data.id}`}
                >
                  {data?.display_name}
                </span>
                <span style={{ padding: 0 }}>
                  <CaretDown />
                </span>
              </button>
              <button
                className="hover-content"
                data-testid={`sync-icon-${cell.value.id}`}
                type="button"
                onClick={() => {
                  trackEvent(EVENTS.resync_profile, {
                    ...eventProperties,
                    active_tab: "talent pool",
                    dev_name: data.display_name,
                    dev_id: data.id,
                  });
                  handleResyncProfile(data.id);
                }}
              >
                <span style={{ padding: 0 }}>
                  <SyncRecruitee
                    isLoading={devProfiles.loading && refreshingProfileId === data.id}
                  />
                </span>
              </button>
            </div>

            {data?.matched_skills?.length > 0 && (
              <div className="d-flex flex-wrap mt-2 matched" data-testid="skills_matched">
                Skills matched:{" "}
                {data?.matched_skills.map((skill, index) => (
                  <span className="p-0 ps-1" key={index}>
                    {skill}
                    {data.matched_skills.length - 1 !== index && ", "}
                  </span>
                ))}
              </div>
            )}

            {data?.matched_countries?.length > 0 && (
              <div className="d-flex flex-wrap matched" data-testid="countries_matched">
                countries matched:{" "}
                {data?.matched_countries.map((skill, index) => (
                  <span className="p-0 ps-1" key={index}>
                    {skill}
                    {data.matched_countries.length - 1 !== index && ", "}
                  </span>
                ))}
              </div>
            )}
          </td>
        );
      }
      case "status": {
        const isOnAssignment = cell.value;
        const className = isOnAssignment ? "deactivated" : "completed";
        const text = isOnAssignment ? "On Assignment" : "Available";

        return (
          <td key={`status${key}`} style={{ width: "32%" }}>
            <span className={`px-2 ${className}`}>{text}</span>
          </td>
        );
      }
      case "joined":
        return (
          <td key={`joined${key}`} style={{ width: "16%" }}>
            {moment.utc(cell.value).format("DD MMM YYYY")}
          </td>
        );

      case "actions": {
        return (
          <td key={`actions${key}`} className="d-table-cell">
            <div className="dropdown">
              <button
                style={{ boxShadow: "none", marginTop: "-5px" }}
                className="btn px-0"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                type="button"
              >
                <span
                  className="px-0"
                  style={{ fontSize: "1.5rem" }}
                  data-testid={`dot-${cell.value.id}`}
                >
                  ...
                </span>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  data-testid={`preview-profile-${cell.value.id}`}
                  type="button"
                  className="dropdown-item"
                  onClick={() => handleUserProfile(cell.value.id)}
                >
                  Preview Profile
                </button>
                <button
                  data-testid={`view-contact-${cell.value.id}`}
                  type="button"
                  className="dropdown-item"
                  onClick={() => handleUserContactInfo(cell.value)}
                >
                  View contact information
                </button>
              </div>
            </div>
          </td>
        );
      }

      default:
        return null;
    }
  };

  const talentPoolTableColumns = useMemo(() => {
    return [
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Joined",
        accessor: "joined",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ];
  }, [JSON.stringify(results)]);

  return (
    <>
      <MetaTags title={filters[filter]} description="View talents profile." />

      <div className="table-responsive">
        <Table>
          <ReactTable
            tableData={tableData(results)}
            tableColumns={talentPoolTableColumns}
            currentPage={currentPage}
            count={count || 0}
            getTableDisplayValue={getTableDisplayValue}
            loadPage={onLoadMore}
            pageSize={page_size}
            isLoading={isFetching.talentPool}
            noDataMessage="No users found"
          />
        </Table>
      </div>
    </>
  );
};

const Table = styled("div")`
  .user-row {
    :hover {
      .hover-content {
        display: block;
      }
    }
  }

  .hover-content {
    display: none;
    cursor: pointer;
    margin-left: "-8px";
    background: none;
    border: none;
  }

  .dropdown-item {
    background: #fff;
    color: #3e4857;
    cursor: pointer;
  }

  .matched,
  .matched span {
    font-size: 12px;
    font-weight: 400;
    padding: 0px;
    line-height: 14px;
    align-items: center;
  }
`;

PoolTable.propTypes = {
  filter: PropTypes.string.isRequired,
  currentPage: PropTypes.number,
  page_size: PropTypes.number,
  setPageSize: PropTypes.func,
  setCurrentPage: PropTypes.func,
};

export default PoolTable;
