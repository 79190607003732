/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import moment from "moment";
import { Badge, DropdownMenu, DropdownToggle } from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";

/* -------------------------- Internal dependencies ------------------------- */
import Icon from "../../components/Icon";
import {
  APPROVE_ACTION,
  ARCHIVE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  GENERATE_INVOICE_ACTION,
  MARK_AS_PAID_ACTION,
  REJECT_ACTION,
  SEND_FOR_APPROVAL_ACTION,
  SEND_REMINDER,
  UNARCHIVE_ACTION,
} from "../../configs/constants/invoiceConstants";
import {
  canApproveInvoice,
  canArchiveInvoice,
  canDeleteInvoice,
  canEditInvoice,
  canMarkInvoiceAsPaid,
  canRejectInvoice,
  canSendInvoiceForApproval,
  canSendInvoiceReminder,
  canUnarchiveInvoice,
} from "../auth";
import { ENDPOINT_INVOICES, INVOICE_TYPE_CREDIT_NOTE } from "../api";
import { showAction } from "./invoiceActions";
import { Reminder } from "./index";
import ActionItem from "../../pages/Dashboard/payments/components/ActionItem";
import DropdownActionItem from "../../pages/Dashboard/payments/components/DropdownActionItem";
import PaymentStatus from "../../pages/Dashboard/payments/components/PaymentStatus";
import { StyledButtonDropdown } from "../../pages/Dashboard/payments/styles";

const invoiceType = {
  "sale": "Sale",
  "final": "Final",
  "credit_nota": "Credit Note",
  "commitment": "Commitment",
};

export const invoiceScope = {
  "multiple_developers": "Multiple Invoice",
  "partial": "Partial Invoice",
};

/**
 * Renders cell value for the payments table
 * @param {Object} cell - The cell information from react-table
 * @param {Object} props - The component props
 * @param {Function} toggleAction - Function to toggle action dropdown
 * @param {Number} open - Currently open dropdown ID
 * @param {Object} settings - Additional settings for the table display
 * @returns {JSX.Element} - The rendered cell content
 */
export const getTableDisplayValue = (cell, props, toggleAction, open, settings = {}) => {
  const invoice = cell?.value;
  const key = `cell-${cell.column.id}-${cell.row.id}`;
  const {
    setReferenceElement,
    setPopperElement,
    styles,
    attributes,
    dropDownPortal,
    checkItem,
    project,
    scope,
    checked,
    handleInvoiceHistory,
  } = settings;

  // eslint-disable-next-line default-case
  switch (cell.column.id) {
    case "expander": {
      return (
        <td key={`expander${key}`} className="expander-cell">
          {cell.row.canExpand ? (
            <button
              type="button"
              className="btn-trans expander-btn"
              {...cell.row.getToggleRowExpandedProps()}
            >
              {cell.row.isExpanded ? (
                <Icon name="arrow-down" size="sm" />
              ) : (
                <Icon name="arrow-right" size="sm" />
              )}
            </button>
          ) : null}
        </td>
      );
    }

    case "batch_action": {
      const isBoxChecked = checked?.findIndex((a) => a.id === invoice.id) !== -1;
      const showCheckbox = invoice && !invoice?.paid && invoice?.invoice_scope !== "partial";

      return (
        <td key={`batch_action${key}`}>
          {showCheckbox && (
            <input
              type="checkbox"
              name="check-it"
              className="custom-checkbox"
              data-testid={`checkbox-${key}`}
              id={invoice?.id}
              checked={isBoxChecked}
              onClick={() => checkItem(invoice)}
            />
          )}
        </td>
      );
    }
    case "created_at": {
      return (
        <td key={`created_at${key}`} className="nowrap">
          {moment.utc(invoice).format("DD MMM YYYY")}
        </td>
      );
    }
    case "title": {
      return (
        <td className="nowrap" key={`title${key}`}>
          <div className="d-flex flex-column">
            <button
              type="button"
              className="btn-trans text-capitalize line-bottom width-fit-content"
              onClick={() => handleInvoiceHistory(invoice)}
            >
              {invoice?.title}{" "}
            </button>
            <div className="d-flex gap-2">
              <PaymentType className="ms-1">{invoiceType[invoice.type]}</PaymentType>
              {invoice.invoice_scope !== "single_developer" && (
                <InvoiceScope className="ms-1">{invoiceScope[invoice.invoice_scope]}</InvoiceScope>
              )}
            </div>
          </div>
        </td>
      );
    }
    case "exact_invoice_url": {
      return (
        <td className="nowrap" key={`exact_invoice_url${key}`}>
          {invoice ? (
            <a
              className="line-bottom"
              href={`${invoice}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              View in Exact
            </a>
          ) : (
            <b>N/A</b>
          )}
        </td>
      );
    }
    case "client": {
      return (
        <td key={`client${key}`} className="nowrap">
          {cell.value.project?.owner ? cell.value.project?.owner?.display_name : "N/A "}
        </td>
      );
    }
    case "project": {
      return (
        <td className="nowrap" key={`project${key}`}>
          <Link className="line-bottom" to={`/projects/${invoice.project.id}/pay/payments/`}>
            {invoice?.project?.title || "N/A"}
          </Link>
        </td>
      );
    }
    case "invoice": {
      return (
        <td key={`invoice${key}`} className="nowrap">
          {invoice && invoice?.finalized ? (
            <a
              className="line-bottom"
              href={`${ENDPOINT_INVOICES}${invoice.id}/download/?format=pdf`}
              target="_blank"
              rel="noreferrer"
            >
              {invoice.number}
            </a>
          ) : (
            <b>N/A</b>
          )}
        </td>
      );
    }
    case "amount": {
      const currency = invoice.currency === "USD" ? "$" : "€";

      return (
        <td key={`amount${key}`} className="nowrap">
          {`${invoice && invoice?.type === INVOICE_TYPE_CREDIT_NOTE ? "-" : ""}${currency}${
            invoice && invoice?.subtotal
          }`}
        </td>
      );
    }

    case "reminder_count": {
      return (
        <>
          {canSendInvoiceReminder() ? (
            <Tip className="reminder-last-sent-text" key={`reminder_count${key}`}>
              <div
                data-tooltip={
                  invoice.reminder_count > 0
                    ? moment.utc(invoice.reminder_sent_at).format("DD MMM YYYY")
                    : "No Reminder Sent"
                }
              >
                {invoice.reminder_count}
              </div>
            </Tip>
          ) : null}
        </>
      );
    }
    case "due_date": {
      if (!invoice) return null;
      return (
        <td key={`due_date${key}`} className="nowrap">
          {moment.utc(invoice.due_at).format("DD MMM YYYY")}
          {new Date(`${invoice.due_at}`) < new Date() && !invoice.paid && (
            <Icon className="danger" name="exclamation-triangle" />
          )}
        </td>
      );
    }
    case "status": {
      return (
        <td key={`status${key}`} className="nowrap">
          <PaymentStatus invoice={invoice} isSaving={props.isSaving} />
        </td>
      );
    }
    case "email": {
      const val = cell.value;
      return (
        <td key={`email${key}`} className="nowrap">
          {val === "failed" ? (
            <span className="overdue" style={{ padding: 6 }}>
              Failed
            </span>
          ) : val === "sent" ? (
            <span className="completed" style={{ padding: 6 }}>
              Sent
            </span>
          ) : val === "pending" ? (
            <span className="pending" style={{ padding: 6 }}>
              Pending
            </span>
          ) : null}
        </td>
      );
    }
    case "actions": {
      const cellValue = cell.value;
      if (!cellValue) return null;
      return (
        <td key={`actions${key}`} className="cta nowrap">
          <div className="cta-wrapper">
            {showAction(GENERATE_INVOICE_ACTION, cellValue) && (
              <ActionItem
                className="line-bottom"
                {...props}
                action={GENERATE_INVOICE_ACTION}
                invoice={cellValue}
              >
                Generate Invoice
                <Icon name="round-arrow-back-ios" className="mx-2" />
              </ActionItem>
            )}

            {(showAction(MARK_AS_PAID_ACTION, cellValue) ||
              showAction(ARCHIVE_ACTION, cellValue) ||
              showAction(EDIT_ACTION, cellValue) ||
              showAction(DELETE_ACTION, cellValue) ||
              showAction(APPROVE_ACTION, cellValue) ||
              showAction(REJECT_ACTION, cellValue) ||
              showAction(UNARCHIVE_ACTION, cellValue) ||
              showAction(SEND_FOR_APPROVAL_ACTION, cellValue)) && (
              <StyledButtonDropdown
                isOpen={open === cellValue.id}
                toggle={() => toggleAction(cellValue.id)}
              >
                <DropdownToggle data-testid={`btn-more-${cellValue.id}`} ref={setReferenceElement}>
                  <Icon name="dots-horizontal-small" />
                </DropdownToggle>

                {dropDownPortal(
                  <DropdownMenu
                    className="dropdown-menu"
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                  >
                    {canMarkInvoiceAsPaid() && (
                      <DropdownActionItem
                        {...props}
                        action={MARK_AS_PAID_ACTION}
                        invoice={cellValue}
                      >
                        <Icon name="circle-check" />
                        &nbsp;&nbsp;&nbsp; Mark as Paid
                      </DropdownActionItem>
                    )}

                    {canSendInvoiceReminder() && (
                      <DropdownActionItem {...props} action={SEND_REMINDER} invoice={cellValue}>
                        <Reminder />
                        &nbsp;&nbsp;&nbsp; Send Reminder
                      </DropdownActionItem>
                    )}

                    {canArchiveInvoice() && (
                      <DropdownActionItem {...props} action={ARCHIVE_ACTION} invoice={cellValue}>
                        <Icon name="archive-outline" />
                        &nbsp;&nbsp;&nbsp; Archive
                      </DropdownActionItem>
                    )}

                    {canUnarchiveInvoice() && (
                      <DropdownActionItem {...props} action={UNARCHIVE_ACTION} invoice={cellValue}>
                        <Icon name="archive-outline" />
                        &nbsp;&nbsp;&nbsp; Unarchive
                      </DropdownActionItem>
                    )}

                    {canSendInvoiceForApproval() && (
                      <DropdownActionItem
                        {...props}
                        action={SEND_FOR_APPROVAL_ACTION}
                        invoice={cellValue}
                      >
                        <Icon name="circle-check" />
                        &nbsp;&nbsp;&nbsp; Send for approval
                      </DropdownActionItem>
                    )}

                    {canApproveInvoice() && (
                      <DropdownActionItem {...props} action={APPROVE_ACTION} invoice={cellValue}>
                        <Icon name="circle-check" />
                        &nbsp;&nbsp;&nbsp; Approve
                      </DropdownActionItem>
                    )}

                    {canRejectInvoice() && (
                      <DropdownActionItem {...props} action={REJECT_ACTION} invoice={cellValue}>
                        <Icon name="cancel" />
                        &nbsp;&nbsp;&nbsp; Reject
                      </DropdownActionItem>
                    )}

                    {canEditInvoice() && (
                      <DropdownActionItem {...props} action={EDIT_ACTION} invoice={cellValue}>
                        <Icon name="circle-edit-outline" />
                        &nbsp;&nbsp;&nbsp; Edit
                      </DropdownActionItem>
                    )}

                    {canDeleteInvoice() && (
                      <DropdownActionItem
                        {...props}
                        action={DELETE_ACTION}
                        invoice={{ ...cellValue, scope, project }}
                      >
                        <Icon name="delete-outline" />
                        &nbsp;&nbsp;&nbsp; Delete
                      </DropdownActionItem>
                    )}
                  </DropdownMenu>,
                )}
              </StyledButtonDropdown>
            )}
          </div>
        </td>
      );
    }

    default:
      return null;
  }
};

const Tip = styled.td`
  border-bottom: 1px solid #edf1f7;
  margin-left: -17px;

  *[data-tooltip] {
    position: relative;
  }

  *[data-tooltip]::after {
    content: attr(data-tooltip);

    position: absolute;
    top: -10px;
    right: -10px;
    width: 90px;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -ms-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;

    font-size: 12px;
    line-height: 16px;
    background: #edf1f7;
    padding: 2px 2px;
    border-radius: 3px;
    border: 1px solid #c0c0c0;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
  }

  *[data-tooltip]:hover::after {
    opacity: 1;
  }

  &.reminder-last-sent-text {
    padding-left: -24px !important;
  }
`;

const StyledBadge = styled(Badge)`
  color: ${(props) =>
    props.colorVariant === "green"
      ? "rgb(33, 150, 83)"
      : props.colorVariant === "blue"
      ? "rgb(51, 102, 204)"
      : props.colorVariant || "rgb(33, 150, 83)"} !important;
  background: ${(props) =>
    props.colorVariant === "green"
      ? "rgba(33, 150, 83, 0.04)"
      : props.colorVariant === "blue"
      ? "rgba(51, 102, 204, 0.04)"
      : `rgba(${props.colorVariant || "33, 150, 83"}, 0.04)`} !important;
  border: 1px solid
    ${(props) =>
      props.colorVariant === "green"
        ? "rgba(33, 150, 83, 0.04)"
        : props.colorVariant === "blue"
        ? "rgba(51, 102, 204, 0.04)"
        : `rgba(${props.colorVariant || "33, 150, 83"}, 0.04)`} !important;
  font-size: 10px !important;
  width: fit-content;
`;

export const PaymentType = (props) => <StyledBadge colorVariant="green" {...props} />;
export const InvoiceScope = (props) => <StyledBadge colorVariant="blue" {...props} />;
