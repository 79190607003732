// function sumInvoiceAmounts(invoices) {
//   return invoices
//     .map((invoice) => {
//       return invoice.total_amount || invoice.amount || 0;
//     })
//     .reduce((total, number) => {
//       return total + Math.round(number * 100) / 100;
//     }, 0);
// }

/**
 * Group invoices with the same reference into one batch object
 * @param {Array} invoices - List of invoices
 * @returns {Array} Returns array of batch objects
 */
export function batchInvoices(invoices) {
  const batches = {};

  invoices?.forEach((invoice) => {
    const ref = invoice.batch_ref;

    if (!batches[ref]) {
      // Initialize the batch object if it doesn't exist
      batches[ref] = {
        ...invoice, // Copy properties from the invoice
        ref, // Set the reference
        invoices: [], // Initialize the invoices array
        total_amount: 0, // Initialize the total amount
      };
    }

    // Add the invoice to the batch
    batches[ref].invoices.push(invoice);

    // Accumulate the total amount
    batches[ref].total_amount += invoice.total_amount || invoice.amount || 0;
  });

  // Return the batch objects as an array
  return Object.values(batches);
}

/**
 * Group partial invoices under their parent multiple_developers invoice based on project ID and parent ID
 * @param {Array} invoices - List of invoices
 * @returns {Array} Returns array of invoices with partial invoices grouped under their parent
 */
export function batchPartialInvoices(invoices) {
  if (!invoices || !invoices.length) {
    return [];
  }

  // Create a map to track which invoices should be included in the final result
  const includeInResult = new Map();
  invoices.forEach((invoice) => includeInResult.set(invoice.id, true));

  // Group invoices by project
  const projectGroups = {};

  // First pass: group all invoices by project ID
  invoices.forEach((invoice) => {
    const projectId = invoice.project?.id;
    if (!projectId) return; // Skip invoices with no project

    if (!projectGroups[projectId]) {
      projectGroups[projectId] = [];
    }
    projectGroups[projectId].push(invoice);
  });

  // Map to store parent invoices by their ID
  const parentInvoiceMap = new Map();

  // Process each project group to identify and associate parent-child relationships
  Object.keys(projectGroups).forEach((projectId) => {
    const projectInvoices = projectGroups[projectId];

    // Separate parent invoices (multiple_developers) and partial invoices
    const parentInvoices = projectInvoices.filter(
      (invoice) => invoice.invoice_scope === "multiple_developers",
    );

    const partialInvoices = projectInvoices.filter(
      (invoice) => invoice.invoice_scope === "partial",
    );

    // Process each parent invoice
    parentInvoices.forEach((parentInvoice) => {
      // Store parent invoice in map with initial empty subrows
      parentInvoiceMap.set(parentInvoice.id, {
        ...parentInvoice,
        subRows: [],
        has_children: false,
        total_amount: parentInvoice.total_amount || parentInvoice.amount || 0,
      });
    });

    // Process each partial invoice
    partialInvoices.forEach((partialInvoice) => {
      const parentId = partialInvoice.parent_invoice;

      // If the partial invoice has a parent_invoice ID and that parent exists in our map
      if (parentId && parentInvoiceMap.has(parentId)) {
        const parent = parentInvoiceMap.get(parentId);

        // Create a new parent object with the partial invoice added to subRows
        const updatedParent = {
          ...parent,
          subRows: [...parent.subRows, partialInvoice],
          has_children: true,
        };

        // Update the parent in the map
        parentInvoiceMap.set(parentId, updatedParent);

        // Mark this partial invoice to be excluded from final top-level result
        includeInResult.set(partialInvoice.id, false);
      }
    });
  });

  // Calculate total amounts for parent invoices based on their partial invoices
  parentInvoiceMap.forEach((parentInvoice, parentId) => {
    if (parentInvoice.has_children && parentInvoice.subRows.length > 0) {
      // Calculate the total amount from partial invoices
      const totalAmount = parentInvoice.subRows.reduce(
        (sum, invoice) => sum + (invoice.total_amount || invoice.amount || 0),
        0,
      );

      // Create a new parent object with the updated total amount
      const updatedParent = {
        ...parentInvoice,
        total_amount: totalAmount,
      };

      // Update the parent in the map
      parentInvoiceMap.set(parentId, updatedParent);
    }
  });

  // Build the final result array, preserving the original order
  const result = [];
  invoices.forEach((invoice) => {
    if (includeInResult.get(invoice.id)) {
      if (parentInvoiceMap.has(invoice.id)) {
        // This is a parent invoice, add it with its children
        result.push(parentInvoiceMap.get(invoice.id));
      } else {
        // This is a regular invoice, add it with empty subRows
        result.push({
          ...invoice,
          has_children: false,
          subRows: [],
        });
      }
    }
  });

  return result;
}
