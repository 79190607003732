/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import IconButton from "../../../components/IconButton";
import { StyledSearchInput } from "../../../utils/styles";

const PoolFilters = ({
  links,
  clearSearch,
  totalFilters,
  handleOpenFilters,
  searchTerm,
  handleChange,
}) => {
  return (
    <Navbar data-testid="talent-pool-nav" className="d-flex justify-content-between">
      <div className="filter-links">
        {links.map((link, i) => {
          return (
            <NavLink key={`pool-filters--${i}`} to={`/${link.route}`} activeClassName="active">
              {link.name}
            </NavLink>
          );
        })}
      </div>

      <div className="d-flex">
        <Button
          className="filter-btn text-dark-blue me-3"
          type="button"
          onClick={handleOpenFilters}
          data-testid="filter-btn"
        >
          <div className="d-flex">
            <Icon className="me-2 mt-1" name="filter-variant" size="xs" width={18} />
            Filters
            {totalFilters > 0 && (
              <span className="badge bg-primary rounded-circle">
                {totalFilters > 9 ? "9+" : totalFilters}
              </span>
            )}
          </div>
        </Button>
        <StyledSearchInput
          data-testid="talent-search"
          type="search"
          value={searchTerm}
          onChange={handleChange}
          autoComplete="off"
          placeholder="Search Talent Pool"
          prepend={<Icon name="search1" />}
          isAppendText={false}
          append={
            <IconButton
              data-testid="clear-btn"
              className="search-close-btn bsd-search-ic"
              name="x-circle"
            />
          }
          appendFunc={clearSearch}
        />
      </div>
    </Navbar>
  );
};

const Navbar = styled.div`
  margin-bottom: 40px;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #151a30;
    display: inline-block;
  }

  .badge {
    width: 20px;
    height: 20px;
    font-size: 10px;
    line-height: 12px;
    margin-left: 5px;
  }

  .filter-links {
    display: flex;
    align-items: center;

    a {
      position: relative;
      display: inline-block;
      font-weight: 500;
      letter-spacing: 0.05em;
      margin-right: 39px;
      text-decoration: none;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 21px;
      color: #7f8c9f;

      &.active {
        font-weight: 500;
        color: #da3451;
      }
    }
  }

  button.btn.filter-btn {
    height: unset;
    line-height: unset;
    background: rgba(143, 155, 179, 0.05);

    :hover,
    :active {
      color: ${(props) => props.theme.colors.gray || "initial"};
    }
  }
`;

const proptypes = {
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clearSearch: PropTypes.func,
  handleOpenFilters: PropTypes.func,
  handleChange: PropTypes.func,
  searchTerm: PropTypes.string,
  totalFilters: PropTypes.number,
};

PoolFilters.propTypes = proptypes;

export default PoolFilters;
