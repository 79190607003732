/* eslint-disable */
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { FormGroup } from "reactstrap";
import styled from "styled-components";
import _, { debounce } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";

import Input from "../Input";
import FieldError from "../FieldError";
import {
  cleanSkills as cleanProjects,
  ENDPOINT_INVOICES,
  INVOICE_TYPES,
  INVOICE_TYPE_COMMITMENT,
  INVOICE_TYPE_CREDIT_NOTE,
  INVOICE_TYPE_FINAL,
  INVOICE_TYPE_PURCHASE,
  INVOICE_TYPE_SALE,
} from "../../utils/api";
import { SEARCH_INITIAL_LIST } from "../../configs/constants/global";
import { StyledDateTimePicker, StyledForm } from "../../utils/styles";
import Icon from "../Icon";
import usePrevious from "../../hooks/usePrevious";
import ProjectSelector from "../ProjectSelector";
import { isAdmin } from "../../utils/auth";
import Select from "../Select";
import SearchSelector from "../SearchSelector";
import PayoutInvoiceItem from "./PayoutInvoiceItem";

const getGrandTotal = (payouts) => {
  const total = Object.values(payouts || {}).reduce((total, { amount }) => {
    return total + Math.floor(parseFloat(amount || 0));
  }, 0);

  return total;
};

const InvoiceForm = ({ id, invoice, payouts, proceed, errors }) => {
  const firstCall = useRef(false);

  const { project, projects } = useSelector(({ Projects }) => Projects);

  const [state, setState] = useState(() => {
    return {
      invoice: invoice || {},
      payouts: payouts || { 0: {} },
      project: { ...(project && Object.keys(project).length !== 0 ? project : invoice.project) },
      projectTitle: invoice?.projectTitle || "",
      error: null,
      errors: errors || null,
      grandTotal: getGrandTotal(payouts),
    };
  });

  const [invoices, setInvoices] = useState([]);
  const [commitmentPayment, setCommitmentPayment] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);

  const currency =
    (state?.invoice?.currency || state?.project?.currency) &&
    `(in ${state?.invoice?.currency || state?.project?.currency || "EUR"})`;

  const prevState = usePrevious(state);

  const fetchProjectCommitment = async (projectId, search) => {
    setLoading(true);
    const response = await axios.get(ENDPOINT_INVOICES, {
      params: {
        types: INVOICE_TYPE_COMMITMENT,
        project: projectId,
        show_all: true,
        search,
      },
    });

    firstCall.current = true;
    if (!firstCall.current) {
      localStorage.setItem(SEARCH_INITIAL_LIST, JSON.stringify(response?.data?.results || []));
    }

    setCommitmentPayment(response?.data?.results || []);
    setLoading(false);
  };

  const fetchInvoices = async (projectId, search) => {
    setLoading(true);

    const response = await axios.get(ENDPOINT_INVOICES, {
      params: {
        types: INVOICE_TYPE_SALE,
        project: +projectId,
        show_all: true,
        search,
      },
    });

    firstCall.current = true;

    if (!firstCall.current) {
      localStorage.setItem(SEARCH_INITIAL_LIST, JSON.stringify(response?.data?.results || []));
    }

    setInvoices(response.data?.results);
    setLoading(false);
  };

  const getSearchedItems = useMemo(() => {
    return debounce((projectId, search) => {
      fetchProjectCommitment(projectId, search);
    }, 500);
  }, []);

  const getSearchedInvoiceItems = useMemo(() => {
    return debounce((projectId, search) => {
      fetchInvoices(projectId, search);
    }, 500);
  }, []);

  const onChangeValue = (key, value) => {
    setState((prev) => ({
      ...prev,
      invoice: { ...prev.invoice, [key]: value },
      ...(key === "project" ? { [key]: value } : {}),
      ...(key === "projectTitle" ? { [key]: value } : {}),
      errors: { ...prev.errors, [key]: null },
    }));
  };

  const onPayoutUpdate = (idx, key, value) => {
    const newPayout = {};
    const newState = {};
    if (!value && key === "user") {
      return;
    }

    newPayout[key] = value;
    newState[idx] = {
      ...(state.payouts[idx] || {}),
      ...newPayout,
    };

    setState((prev) => {
      const newPayouts = { ...prev.payouts, ...newState };
      const grandTotal = getGrandTotal(newPayouts);

      return { ...state, payouts: newPayouts, grandTotal };
    });
  };

  const removePayoutByIndex = (index) => {
    setState((prevState) => {
      const updatedPayouts = { ...prevState.payouts };
      if (updatedPayouts.hasOwnProperty(index)) {
        delete updatedPayouts[index];
      }
      return {
        ...prevState,
        payouts: updatedPayouts,
      };
    });
  };

  const checkPayoutRequired = (payout) => {
    if (Object.keys(state.payouts).length === 1) {
      return true;
    }

    if (Object.keys(payout).length > 0 && payout.user) {
      return true;
    }
    return false;
  };

  const isPayment = [
    INVOICE_TYPE_SALE,
    INVOICE_TYPE_CREDIT_NOTE,
    INVOICE_TYPE_COMMITMENT,
    INVOICE_TYPE_FINAL,
  ].includes(state.invoice.type);

  const onChangeField = (e, key) => onChangeValue(key, e.target.value);

  const renderNewPayout = (idx) => {
    const payout = state.payouts[idx] || {};
    const participants = project?.participants || state.project?.participants;
    const user = payout.user ? [payout.user] : [];
    const amount = payout.amount || "";
    const description = payout.description || "";
    const lineItemType = payout.line_item_type || "";

    const updatePayout = (key, value) => onPayoutUpdate(idx, key, value);
    const remove = () => removePayoutByIndex(idx);

    return (
      <PayoutInvoiceItem
        {...{
          idx,
          participants,
          user,
          updatePayout,
          amount,
          description,
          lineItemType,
          currency,
          remove,
          required: checkPayoutRequired(payout),
        }}
      />
    );
  };

  const onSave = (e) => {
    e.preventDefault();

    const { project, payouts, invoice } = state;

    // Check if project or invoice is valid
    if (!(project?.id || invoice?.id || !_.isEmpty(project))) {
      setState((prevState) => ({
        ...prevState,
        errors: { project_title_error: "Select a project" },
      }));
      return false;
    }

    // Skip team validation for payments
    if (!isPayment) {
      const error = Object.values(payouts).find((payout) => !payout.user && payout.amount);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          error: { message: "Select team member", section: "team" },
        }));
        return false;
      }
    }

    // Proceed with the invoice process
    if (proceed) {
      const newInvoice = { ...state.invoice };

      if (invoice.type === INVOICE_TYPE_PURCHASE) {
        proceed({
          invoice: newInvoice,
          payouts,
          project,
        });
      } else {
        if (
          (invoice.type === INVOICE_TYPE_FINAL || invoice.type === INVOICE_TYPE_CREDIT_NOTE) &&
          state?.invoice?.ref_invoice?.id
        ) {
          newInvoice.ref_invoice = { id: state.invoice.ref_invoice.id };
        } else {
          delete newInvoice.ref_invoice;
        }

        proceed({ ...newInvoice, payouts });
      }
    }

    return true;
  };

  const onAddPayout = () => {
    const { payouts } = state;
    const idx =
      Object.keys(payouts).length > 0 ? Math.max(...Object.keys(payouts).map(Number)) + 1 : 0;
    setState((prevState) => ({
      ...prevState,
      payouts: { ...prevState.payouts, [idx]: {} },
    }));
  };

  useEffect(() => {
    if (project?.id || invoice?.id) {
      if (state.invoice.type === INVOICE_TYPE_FINAL) {
        fetchProjectCommitment(project?.id || invoice?.id).then(() => {
          setShowSuggestions(true);
        });
      } else if (state.invoice.type === INVOICE_TYPE_CREDIT_NOTE) {
        fetchInvoices(project?.id || invoice?.id).then(() => {
          setShowSuggestions(true);
        });
      }
    }
  }, [state.invoice.type, project?.id, invoice?.id]);

  useEffect(() => {
    if (errors && Object.keys(errors).length) setState((prev) => ({ ...prev, errors }));
  }, [errors]);

  const filterProjects = (category) => {
    const filteredProjects = cleanProjects(projects).filter((proj) => proj.type === category);
    return filteredProjects;
  };

  let commitment_amount =
    state.invoice?.ref_invoice?.amount ??
    state.invoice?.project?.commitment_amount_balance ??
    state.invoice.commitment_amount ??
    "";

  return (
    <Container id={id} data-testid="invoice-form" onSubmit={onSave}>
      {/* Project not pre-selected means comes from dashboard */}
      {!(project?.id || invoice?.id) && (
        <FormGroup data-testid="projectInput">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            Project
            <LabelStyle>
              <span className="label-style">*</span>
            </LabelStyle>
          </label>
          {state.errors && state.errors.project_title_error ? (
            <FieldError message={state.errors.project_title_error} />
          ) : null}
          <ProjectSelector
            onChange={(title) => onChangeValue("projectTitle", title)}
            handleProjectObj={(proj) => {
              onChangeValue("project", proj);
              commitment_amount = proj?.commitment_amount_balance;

              // reset ref Invoice
              setState((prev) => ({
                ...prev,
                invoice: {
                  ...prev.invoice,
                  ref_invoice: {},
                },
              }));

              if (state.invoice.type === INVOICE_TYPE_CREDIT_NOTE) {
                fetchInvoices(proj.id).then(() => {
                  setShowSuggestions(true);
                });
              }

              if (state.invoice.type === INVOICE_TYPE_FINAL) {
                fetchProjectCommitment(proj.id, "").then(() => {
                  setShowSuggestions(true);
                });
              }
            }}
            value={state.projectTitle}
            selected={filterProjects("project") || []}
            placeholder="Choose project"
            queryParams={{
              stage: "active",
              archived: "False",
              show_all: isAdmin(),
            }}
          />
        </FormGroup>
      )}

      {state.invoice.type === INVOICE_TYPE_FINAL && (
        <FormGroup>
          <label
            htmlFor="commitment_payment"
            data-testid="commitment_payment"
            className="control-label"
          >
            Commitment Payment
          </label>
          {state.errors && state.errors.ref_invoice && (
            <FieldError message={state.errors.ref_invoice} />
          )}
          <SearchSelector
            loading={loading}
            showSuggestions={showSuggestions}
            handleSelectObj={(obj) => {
              onChangeValue("ref_invoice", obj);
              onChangeValue("commitment_amount", +obj.amount);

              setShowSuggestions(false);
            }}
            itemList={commitmentPayment || []}
            actionDispatch={(search) => {
              if (search === "") {
                onChangeValue("ref_invoice", {});
              } else {
                getSearchedItems(state?.project?.id || state?.invoice?.id, search);
              }
            }}
            disabled={!(state?.invoice?.project?.id || state?.project?.id)}
            value={state.invoice?.ref_invoice?.title || ""}
            selected={state.invoice?.ref_invoice?.title || ""}
            placeholder="Select/search commitment payment"
            queryParams={{}}
            setItemList={setCommitmentPayment}
          />
        </FormGroup>
      )}

      <FormGroup>
        <label>
          {INVOICE_TYPES[invoice?.type]} Title
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>

        {state.errors && state.errors.title && <FieldError message={state.errors.title} />}
        <Input
          value={state.invoice.title}
          onChange={(e) => onChangeField(e, "title")}
          placeholder="Enter a title for this payment"
          dataTestId="paymentTitle"
          required
        />
        <div className="text text-sm">Don&#39;t include project title here</div>
      </FormGroup>

      {state.invoice.type === INVOICE_TYPE_CREDIT_NOTE && (
        <FormGroup>
          <label htmlFor="ref_invoices" data-testid="ref_invoices" className="control-label">
            Reference Invoice
          </label>
          {state.errors && state.errors.ref_invoice && (
            <FieldError message={state.errors.ref_invoice} />
          )}
          <SearchSelector
            loading={loading}
            showSuggestions={showSuggestions}
            handleSelectObj={(obj) => {
              onChangeValue("ref_invoice", obj);
              setShowSuggestions(false);
            }}
            itemList={invoices || []}
            actionDispatch={(search) => {
              if (search === "") {
                onChangeValue("ref_invoice", {});
              } else {
                getSearchedInvoiceItems(state?.project?.id || state?.invoice?.id, search);
              }
            }}
            disabled={!state?.project?.id}
            value={state.invoice?.ref_invoice?.title || ""}
            selected={state.invoice?.ref_invoice?.title || ""}
            placeholder="Select/search invoices"
            queryParams={{}}
            setItemList={setInvoices}
          />
        </FormGroup>
      )}

      <FormGroup>
        <label htmlFor="invoice-date">Invoice Date</label>
        {state.errors && state.errors.issued_at && <FieldError message={state.errors.issued_at} />}
        <StyledDateTimePicker
          $calendar
          id="invoice-date"
          data-testid="invoice-date"
          className="tg-date-field"
          placeholder="Enter invoice date"
          format="DD MMM YYYY"
          $time={false}
          value={state.invoice.issued_at ? new Date(state.invoice.issued_at) : null}
          onChange={(issued_at) => {
            onChangeValue("issued_at", moment.utc(issued_at).format());
          }}
          required
        />
      </FormGroup>

      {isPayment ? (
        <>
          {(state.invoice.type === INVOICE_TYPE_CREDIT_NOTE ||
            state.invoice.type === INVOICE_TYPE_COMMITMENT) && (
            <FormGroup>
              <label htmlFor="amount">
                Amount {currency}
                <LabelStyle>
                  <span className="label-style">*</span>
                </LabelStyle>
              </label>
              {state.errors && state.errors.amount && <FieldError message={state.errors.amount} />}
              <Input
                id="amount"
                type="number"
                dataTestId="amountInEur"
                value={state?.invoice?.total_amount || state?.invoice?.amount || ""}
                onChange={(e) => e.target.value >= 0 && onChangeValue("amount", e.target.value)}
                step="0.01"
                placeholder="Enter amount"
                required
              />
            </FormGroup>
          )}

          {state.invoice.type === INVOICE_TYPE_FINAL && (
            <FormGroup>
              <label htmlFor="commitment-amount">
                Commitment Amount {currency}
                <LabelStyle>
                  <span className="label-style">*</span>
                </LabelStyle>
              </label>
              {state.errors && state.errors.commitment_amount && (
                <FieldError message={state.errors.commitment_amount} />
              )}
              <Input
                id="commitment-amount"
                type="number"
                dataTestId="commitment-amount"
                value={commitment_amount}
                onChange={(e) =>
                  e.target.value >= 0 && onChangeValue("commitment_amount", e.target.value)
                }
                step="0.01"
                placeholder="Enter commitment amount"
                required
              />
            </FormGroup>
          )}

          {state.invoice.type !== INVOICE_TYPE_CREDIT_NOTE && (
            <FormGroup>
              <label htmlFor="payment-period" className="control-label">
                Payment Period (in days)
                <LabelStyle>
                  <span className="label-style">*</span>
                </LabelStyle>
              </label>
              {state.errors && state.errors.payment_period && (
                <FieldError message={state.errors.payment_period} />
              )}
              <Select
                id="payment-period"
                data-testid="payment-period"
                onChange={(e) => onChangeField(e, "payment_period")}
                value={state.invoice.payment_period ? state.invoice.payment_period : "14"}
                required
              >
                <option value="7">7</option>
                <option value="14">14</option>
                <option value="21">21</option>
                <option value="30">30</option>
                <option value="60">60</option>
              </Select>
            </FormGroup>
          )}

          {state.invoice.type === INVOICE_TYPE_FINAL && (
            <div className="d-flex flex-column gap-3 items-container">
              {Object.keys(state.payouts).map((idx) => {
                return <Fragment key={idx}>{renderNewPayout(+idx)}</Fragment>;
              })}

              <div className="add-more">
                <button type="button" onClick={onAddPayout}>
                  <Icon name="round-add" size="main" /> Add invoice item
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>
          {state.error && state.error.section === "team" && (
            <FieldError message={state.error.message} />
          )}

          <div className="d-flex flex-column gap-3 items-container">
            {Object.keys(state.payouts).map((idx) => {
              return <Fragment key={idx}>{renderNewPayout(+idx)}</Fragment>;
            })}

            <div className="add-more">
              <button type="button" onClick={onAddPayout}>
                <Icon name="round-add" size="main" /> Add invoice item
              </button>
            </div>
          </div>
        </div>
      )}

      {(state.invoice.type === INVOICE_TYPE_FINAL ||
        state.invoice.type === INVOICE_TYPE_PURCHASE) && (
        <div className="grand-total_container mt-3">
          <div className="total-wrapper">
            <p className="m-0">Grand Total</p>
            <p className="m-0">
              {state?.invoice?.currency === "USD" ? "$" : "€"} {state?.grandTotal}
            </p>
          </div>
        </div>
      )}
    </Container>
  );
};

const Container = styled(StyledForm)`
  .items-container {
    max-height: 500px;
    overflow-y: scroll;
  }

  .grand-total_container,
  .total-wrapper {
    display: flex;
    align-items: center;
  }

  .grand-total_container {
    display: flex;
    align-items: center;
    justify-content: end;

    .total-wrapper {
      background-color: #f5f7fa;
      border: 1px solid #e3e9f2;
      border-radius: 4px;
      padding: 8px 10px;
      column-gap: 30px;
      min-width: 220px;
    }
  }
`;

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

export default InvoiceForm;
