/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ArrowDown } from "../pages/Dashboard/dashboard/Icons";

const Select = (props) => {
  const { options = [], className, size, children, wrapperClassName } = props;

  return (
    <Wrapper className={wrapperClassName}>
      <select
        {...props}
        className={`form-control  ${className || ""} ${size ? `form-control-${size}` : ""}`}
      >
        {children}
        {options &&
          options.map(({ value, name }) => {
            return (
              <option
                key={`option-${value || name}`}
                defaultValue={value || name}
                value={value}
                data-testid={`option-${value || name}`}
              >
                {name}
              </option>
            );
          })}
      </select>
      <ArrowDown className="arrow" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  select {
    appearance: none;

    ::-ms-expand {
      display: none;
    }

    + div {
      position: absolute;
      right: 15px;
      top: 10px;
    }
  }
`;

const propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  wrapperClassName: PropTypes.string,
};

Select.propTypes = propTypes;

export default Select;
