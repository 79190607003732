/* eslint-disable react/jsx-props-no-spreading */
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import styled from "styled-components";
import PropTypes from "prop-types";

import Avatar from "../../Avatar";
import useDidUpdate from "../../../hooks/useDidUpdate";
import usePrevious from "../../../hooks/usePrevious";
import { generateUserInitials } from "../../../utils/stringUtils";

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: "4px",
    fontSize: "12px",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
    width: "300px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "12px",
    color: "#8F9BB3",
  }),
};

const CustomOption = ({ innerProps, data, isFocused }) => (
  <OptionWrapper {...innerProps} isFocused={isFocused}>
    <Avatar image={data.avatar_url} initials={generateUserInitials(data)} size="dash" />
    <span>{data.display_name}</span>
  </OptionWrapper>
);

const CustomDropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <svg width={18} height={10} viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 1L9 8.5L1.5 1"
        stroke="#7F8C9F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </components.DropdownIndicator>
);

const PaymentUserSelector = ({ className, placeholder, selected, onChange, disabled, options }) => {
  const User = useSelector(({ User: UserState }) => UserState);

  const [selectedUser, setSelectedUser] = useState(selected || {});

  const prevSelectedUser = usePrevious(selectedUser);

  const handleChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  useEffect(() => {
    if (selected) {
      setSelectedUser(selected);
    }
  }, [selected]);

  useDidUpdate(() => {
    if (!isEqual(selectedUser, prevSelectedUser) && onChange) {
      onChange(selectedUser);
    }
  }, [selectedUser]);

  return (
    <div data-testid="user-selector" className={`tag-input ${className || ""}`}>
      <StyledSelect
        isDisabled={disabled}
        placeholder={placeholder}
        noOptionsMessage={() => "No users found"}
        loadingMessage={() => "Searching..."}
        isLoading={User?.isFetching?.loading}
        menuPortalTarget={document.querySelector("#modal-root")}
        options={options}
        value={Object.keys(selectedUser).length > 0 ? selectedUser : null}
        onChange={handleChange}
        isSearchable
        getOptionLabel={(option) => option.display_name}
        getOptionValue={(option) => option.id}
        filterOption={(option, inputValue) => {
          const label = option.data.display_name.toLowerCase();
          const username = option.data.username?.toLowerCase() || "";
          const searchValue = inputValue.toLowerCase();
          return label.includes(searchValue) || username.includes(searchValue);
        }}
        components={{
          Option: CustomOption,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        styles={customStyles}
        classNamePrefix="react-select"
      />
    </div>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  cursor: pointer;
  background: ${({ isFocused }) => (isFocused ? "#f8f9fa" : "transparent")};

  &:hover {
    background-color: #f8f9fa;
  }

  .avatar.avatar-dash {
    width: 35px;
    height: 35px;

    &.avatar-initials {
      font-size: 14px;
    }
  }

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #3e4857;
  }
`;

const StyledSelect = styled(Select)`
  .react-select__control {
    min-height: 40px;
    border-color: #edf1f7;
    box-shadow: none;

    &:hover {
      border-color: #edf1f7;
    }

    &--is-focused {
      border-color: #edf1f7;
      box-shadow: 0 0 0 1px #edf1f7;
    }
  }

  .react-select__menu {
    border: 1px solid #edf1f7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

PaymentUserSelector.defaultProps = {
  placeholder: "Type a name or username",
  disabled: false,
  selected: {},
};

PaymentUserSelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.shape({}),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

CustomOption.propTypes = {
  innerProps: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    avatar_url: PropTypes.string,
    display_name: PropTypes.string,
  }).isRequired,
  isFocused: PropTypes.bool.isRequired,
};

export default PaymentUserSelector;
