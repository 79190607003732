import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import Error from "../../../../components/Error";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import FieldError from "../../../../components/FieldError";
import { validateUrl } from "../../../../utils/helpers";

const options = [
  { value: "language", name: " Programming Languages" },
  { value: "framework", name: "Frameworks" },
  { value: "platform", name: "Platforms" },
  { value: "library", name: "Libraries " },
  { value: "storage", name: "Storage Engines" },
  { value: "other", name: " Miscellaneous " },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Skill name is required")
    .min(2, "Skill name must be at least 2 characters")
    .max(50, "Skill name must not exceed 50 characters"),
  type: Yup.string()
    .required("Skill type is required")
    .oneOf(
      options.map((opt) => opt.value),
      "Invalid skill type",
    ),
  assessmentUrl: Yup.string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .test("valid-url", "Please enter a valid URL", (value) => {
      if (!value) return true; // Allow empty values
      return validateUrl(value);
    }),
});

const EditSkillForm = ({ id, skill_name, skill_type, assessment_url, proceed }) => {
  const { errors } = useSelector((state) => state.Skill);
  const errorMessage = errors?.edit_skill_error || errors?.edit_skill_error;

  const formik = useFormik({
    initialValues: {
      name: skill_name || "",
      type: skill_type || "",
      assessmentUrl: assessment_url || "",
    },
    validationSchema,
    onSubmit: (values) => {
      proceed(values);
    },
    validateOnBlur: true,
  });

  return (
    <form id={id} onSubmit={formik.handleSubmit} data-testid="edit-skill-form">
      {errorMessage && <Error message={errorMessage} />}

      <label
        className="control-label mb-2"
        htmlFor="skill_name"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Skill name
      </label>
      <Input
        className="mb-1"
        id="skill_name"
        name="name"
        type="text"
        dataTestId="skill_name"
        placeholder="Enter skill name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
      />
      {formik.touched.name && formik.errors.name && <FieldError message={formik.errors.name} />}

      <label
        className="control-label mb-2 mt-3"
        htmlFor="skill_type"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Associated skills type
      </label>
      <Select
        id="skill_type"
        name="type"
        data-testid="skill_type"
        aria-label="skill-type-input"
        options={options}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.type}
      >
        <option value="">Select skill type</option>
      </Select>
      {formik.touched.type && formik.errors.type && <FieldError message={formik.errors.type} />}

      <label
        className="control-label mb-2 mt-3"
        htmlFor="assessment_url"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Coderbyte Assessment link
      </label>
      <Input
        id="assessment_url"
        name="assessmentUrl"
        type="text"
        dataTestId="assessment_url"
        placeholder="Enter link to the assessment"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.assessmentUrl}
      />
      {formik.touched.assessmentUrl && formik.errors.assessmentUrl && (
        <FieldError message={formik.errors.assessmentUrl} />
      )}
    </form>
  );
};

EditSkillForm.propTypes = {
  id: PropTypes.string.isRequired,
  skill_type: PropTypes.string,
  skill_name: PropTypes.string,
  assessment_url: PropTypes.string,
  proceed: PropTypes.func,
};

export default EditSkillForm;
