import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import SkillsFilterInput from "./SkillFilterInput";
import IconButton from "../../../components/IconButton";
import { FilterContext } from "../../../context/FilterContext";

const selectOptions = [
  { value: "AND", name: "All" },
  { value: "OR", name: "Any" },
];

const StyledSkillsYearsGroup = withTheme(styled.div`
  border-radius: 4px;
  border: 1.5px solid #edf1f7;

  &::after,
  &::before {
    display: block;
    content: "";
    position: relative;
    background-color: #edf1f7;
    width: 2px;
    height: 14px;
    left: 30px;
    top: 100%;
  }

  &::after {
    bottom: 100%;
    transform: translateY(14px);
  }

  &::before {
    top: 100%;
    transform: translateY(-14px);
  }
  button:focus,
  a:focus {
    outline: none;
  }
  select {
    box-shadow: none;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e3e9f2;
    background: #fff;
    font-size: 11px;
    margin-left: 4px;
    margin-right: 4px;
  }
  button.delete-button {
    height: auto;
    vertical-align: baseline;
    line-height: unset;

    i {
      font-size: ${(props) => props.theme.functions.pxToRem(18)};
      margin-left: 14px;
      color: ${(props) => props.theme.colors.gray};
    }

    &:disabled {
      i {
        opacity: 0.3;
      }
    }
  }
`);

const SkillsFilterGroup = ({ groupIndex, group }) => {
  const { filter, updateGroupOperation, addFilterItem, deleteGroup } = useContext(FilterContext);
  const { groups, searchOperation } = filter;

  const onDeleteSkillGroup = (groupIdx) => {
    deleteGroup(groupIdx);
  };

  return (
    <>
      <StyledSkillsYearsGroup>
        <div className="p-3 pb-0">
          <div className="d-flex justify-content-between">
            <div>
              Match{" "}
              <select
                value={group.searchOperation}
                onChange={(ev) => updateGroupOperation(groupIndex, ev.target.value)}
                data-testid="filter-group-operation"
              >
                {selectOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>{" "}
              skills in this group
            </div>
            <IconButton
              name="delete-outline"
              size="sm"
              data-testid="delete-skill-group-btn"
              className="p-0 delete-button"
              onClick={() => onDeleteSkillGroup(groupIndex)}
            />
          </div>

          {group.skills.map((val, skillIndex) => {
            return (
              <SkillsFilterInput
                key={skillIndex}
                groupIndex={groupIndex}
                skillIndex={skillIndex}
                skillName={val.name}
                operation={val.operation}
                years={val.years}
              />
            );
          })}

          <Button
            type="button"
            className="px-0 mt-2 mb-0 text-dark-blue"
            role="link"
            onClick={() => addFilterItem(groupIndex, "skills")}
          >
            <Icon name="round-add" /> Add filter
          </Button>
        </div>
      </StyledSkillsYearsGroup>

      {groupIndex !== groups.length - 1 && (
        <div className="px-4 py-3 fw-bolder" data-testid="search-operation-div">
          {searchOperation}
        </div>
      )}
    </>
  );
};

SkillsFilterGroup.propTypes = {
  groupIndex: PropTypes.number.isRequired,
  group: PropTypes.shape({
    searchOperation: PropTypes.string.isRequired,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        operation: PropTypes.string.isRequired,
        years: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default SkillsFilterGroup;
