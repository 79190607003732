/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export const setupInterceptors = ({ handleLogOut }) => {
  axios.interceptors.response.use(undefined, (err) => {
    const url = `${
      process.env.REACT_APP_NODE_ENV === "production"
        ? `${window.location.origin}/`
        : process.env.REACT_APP_BACKEND_ROOT || "/"
    }api/auth/verify/`;

    if (
      err.request?.responseURL !== url &&
      (err.response?.status === 401 ||
        err.response?.data?.detail === "Authentication credentials were not provided.")
    ) {
      handleLogOut();
    }
    // else if (err.response?.status === 401) {
    //   handleLogOut();
    // }

    return Promise.reject(err);
  });
};

const BACKEND_PATH = process.env.REACT_APP_BACKEND_ROOT || "/";
const API_PATH = "api/";

export const API_ROOT = `${BACKEND_PATH}${API_PATH}`;

const getEndpointUrl = (path) => {
  return API_ROOT + path;
};

// Auth
export const ENDPOINT_LOGIN = getEndpointUrl("auth/login/");
export const ENDPOINT_LOGOUT = getEndpointUrl("auth/logout/");
export const ENDPOINT_VERIFY = getEndpointUrl("auth/verify/");
export const ENDPOINT_REGISTER = getEndpointUrl("auth/register/");
export const ENDPOINT_EMAIL_VISITOR = getEndpointUrl("auth/visitor/");
export const ENDPOINT_APPLY = getEndpointUrl("apply/");
export const ENDPOINT_INVITE = getEndpointUrl("invite/");
export const ENDPOINT_CHANGE_PASSWORD = getEndpointUrl("auth/password/change/");
export const ENDPOINT_RESET_PASSWORD_CONFIRM = getEndpointUrl("auth/password/reset/confirm/");
export const ENDPOINT_PROFILE_SHARE = getEndpointUrl("profile/share/");
export const ENDPOINT_ROLES_PERMISSIONS = getEndpointUrl("roles/");

// ACCOUNT
export const ENDPOINT_PROFILE = getEndpointUrl("me/profile/");
export const ENDPOINT_COMPANY = getEndpointUrl("me/company/");
export const ENDPOINT_ACCOUNT_SETTINGS = getEndpointUrl("me/settings/");
export const ENDPOINT_USER_INFO = getEndpointUrl("me/user/");
export const ENDPOINT_ACCOUNT_INFO = getEndpointUrl("me/account/");
export const ENDPOINT_RESET_PASSWORD = getEndpointUrl("auth/password/reset/");

// Profile
export const ENDPOINT_USER_WORK = getEndpointUrl("work/");
export const ENDPOINT_USER_EDUCATION = getEndpointUrl("education/");
export const ENDPOINT_USER_PROJECT = getEndpointUrl("profile-project/");
export const ENDPOINT_DEV_PROFILE = getEndpointUrl("devprofile/");
export const ENDPOINT_DEVELOPER_TEST_REQUEST = getEndpointUrl("developer-test-request/");
export const ENDPOINT_CODERBYTE_ASSESSMENT_INVITATION = getEndpointUrl(
  "coderbyte-assessment-invitation/",
);
export const ENDPOINT_GIGCV_EXPORT = getEndpointUrl("gigcv/export/");

export const ENDPOINT_GET_PLATFORM_SUMMARY = getEndpointUrl("invoices/platform-summary/");
export const ENDPOINT_INVOICES_SUMMARY = getEndpointUrl("invoices/summary/");
export const ENDPOINT_INVOICES = getEndpointUrl("invoices/");
export const ENDPOINT_PROJECTS = getEndpointUrl("projects/");
export const ENDPOINT_NOTIFICATION_LOG = getEndpointUrl("notification-log/");
export const ENDPOINT_DASHBOARD_STATS = getEndpointUrl("dashboard-stats/");
export const ENDPOINT_USERS = getEndpointUrl("users/");
export const ENDPOINT_PROGRESS_EVENTS = getEndpointUrl("progress-events/");
export const ENDPOINT_PROGRESS_REPORTS = getEndpointUrl("progress-reports/");
export const ENDPOINT_DOCUMENTS = getEndpointUrl("documents/");
export const ENDPOINT_PARTICIPATION = getEndpointUrl("participation/");
export const ENDPOINT_ACTIVITIES = getEndpointUrl("activity/");
export const ENDPOINT_COUNTRIES = getEndpointUrl("countries/");

export const ENDPOINT_NOTIFICATIONS = getEndpointUrl("me/notification/");
export const ENDPOINT_SKILLS = getEndpointUrl("skills/");
export const ENDPOINT_SKILL_SUGGESTIONS = getEndpointUrl("skill-suggestions/");
export const ENDPOINT_TEST_RESULTS = getEndpointUrl("results/");
export const ENDPOINT_PAYONEER_SIGNUP = getEndpointUrl("payoneer/");
export const ENDPOINT_DEVELOPER_RATING = getEndpointUrl("developer-rating/");
export const ENDPOINT_GENERAL_RATING = getEndpointUrl("progress-reports/");

export const WISE_PAYMENT = getEndpointUrl("wise/");
export const DOLLAR_PAYMENT = getEndpointUrl("dollar-account/");
export const PAYONEER_PAYMENT = getEndpointUrl("payoneer/");
export const CHANGE_DEFAULT_ACCOUNT = getEndpointUrl("payment-providers/");
export const IBAN_PAYMENT = getEndpointUrl("iban/");
export const IBAN_UNPAID_EXPORT = getEndpointUrl("invoices/iban/download/");

export const UPLOAD_CSV = getEndpointUrl("invoices/iban/upload/");

export const ENDPOINT_INTEREST_POLLS = getEndpointUrl("interest-polls/");

export const SEND_EMAIL = getEndpointUrl("send-email/");

export const OPPORTUNITIES = getEndpointUrl("opportunity/");

// Time sheet entries
export const ENDPOINT_TIMESHEET_ENTRIES = getEndpointUrl("timesheet-entries/");
export const ENDPOINT_TIMESHEET_ENTRY = getEndpointUrl("timesheet/entry/");

export const USER_TYPE_DEVELOPER = 1;
export const USER_TYPE_PROJECT_OWNER = 2;
export const USER_TYPE_PROJECT_MANAGER = 3;
export const USER_TYPE_DESIGNER = 4;

export const PROJECT_TYPE_WEB = "web";
export const PROJECT_TYPE_MOBILE = "mobile";
export const PROJECT_TYPE_OTHER = "other";

export const PROJECT_DURATION_2_WEEKS = "2w";
export const PROJECT_DURATION_6_MONTHS = "6m";
export const PROJECT_DURATION_PERMANENT = "permanent";

export const PROJECT_STAGE_SHORTLIST = "shortlist";
export const PROJECT_STAGE_ACTIVE = "active";

export const STATUS_INITIAL = "initial";
export const STATUS_INTERESTED = "interested";
export const STATUS_UNINTERESTED = "uninterested";
export const STATUS_ACCEPTED = "accepted";
export const STATUS_REJECTED = "rejected";

export const RECRUITEE_STATUS_CATEGORY_APPLY = "apply";

export const INVOICE_TYPE_FINAL = "final";
export const INVOICE_TYPE_SALE = "sale";
export const INVOICE_TYPE_PURCHASE = "purchase";
export const INVOICE_TYPE_CREDIT_NOTE = "credit_nota";
export const INVOICE_TYPE_COMMITMENT = "commitment";
export const PROGRESS_EVENT_TYPE_CM = "cm";
export const PROGRESS_EVENT_TYPE_CLIENT = "client";
export const PROGRESS_EVENT_TYPE_MILESTONE = "milestone";
export const PROGRESS_EVENT_TYPE_DEVELOPER = "developer";
export const PROGRESS_EVENT_TYPE_CLIENT_DEVELOPER_RATING = "developer_rating";
export const PROGRESS_EVENT_TYPE_DEVELOPER_SURVEY = "developer_survey";
export const PROGRESS_EVENT_TYPE_MILESTONE_INTERNAL = "internal";

export const DOC_TYPE_REQUIREMENTS = "requirements";
export const DOC_TYPE_WIREFRAMES = "wireframes";
export const DOC_TYPE_ESTIMATE = "estimate";
export const DOC_TYPE_PROPOSAL = "proposal";
export const DOC_TYPE_PLANNING = "planning";
export const DOC_TYPE_TIMELINE = "timeline";
export const DOC_TYPE_OTHER = "other";
export const DOC_TYPE_CONTRACT = "contract";

export const DOCUMENT_TYPES = [
  [DOC_TYPE_REQUIREMENTS, "Requirements"],
  [DOC_TYPE_WIREFRAMES, "Wireframes"],
  [DOC_TYPE_ESTIMATE, "Estimate"],
  [DOC_TYPE_PROPOSAL, "Proposal"],
  [DOC_TYPE_PLANNING, "Planning"],
  [DOC_TYPE_TIMELINE, "Timeline"],
  [DOC_TYPE_OTHER, "Other"],
  [DOC_TYPE_CONTRACT, "Contracts"],
];

const docTypesMap = {};
DOCUMENT_TYPES.map((doc) => {
  docTypesMap[doc[0]] = doc[1];
});

export const DOCUMENT_TYPES_MAP = docTypesMap;

export const INVOICE_TYPES = {
  sale: "Payment",
  purchase: "Payout",
  credit_nota: "Credit Note",
  commitment: "Commitment Payment",
  final: "Final Invoice",
};

export function flattenJson(jsonData, key) {
  let flattenedData = {};

  if (jsonData !== null && jsonData !== undefined) {
    if (jsonData instanceof File) {
      if (key) {
        const flattenedUpdate = {};
        flattenedUpdate[key] = jsonData;
        flattenedData = { ...flattenedData, ...flattenedUpdate };
      }
    } else if (Array.isArray(jsonData)) {
      if (key && jsonData.length) {
        jsonData.forEach((item, idx) => {
          flattenedData = {
            ...flattenedData,
            ...flattenJson(item, `${key}[${idx}]`),
          };
        });
      }
    } else if (typeof jsonData === "object") {
      Object.keys(jsonData).forEach((nestedKey) => {
        flattenedData = {
          ...flattenedData,
          ...flattenJson(
            jsonData[nestedKey],
            `${key ? `${key}${key.endsWith("]") ? "" : "."}` : ""}${nestedKey}`,
          ),
        };
      });
    } else if (key) {
      const flattenedUpdate = {};
      flattenedUpdate[key] = jsonData;
      flattenedData = { ...flattenedData, ...flattenedUpdate };
    }
  }
  return flattenedData;
}

export function composeFormData(data) {
  const flattenedData = flattenJson(data);

  const formData = new FormData();
  Object.keys(flattenedData).forEach((key) => {
    formData.append(key, flattenedData[key]);
  });
  return formData;
}

export function cleanSkills(skills) {
  let cleanedData = [];
  if (Array.isArray(skills)) {
    skills.forEach((skill) => {
      cleanedData = [...cleanedData, ...cleanSkills(skill)];
    });
  } else if (typeof skills === "object") {
    cleanedData = [...cleanedData, skills];
  } else if (typeof skills === "string") {
    skills.split(",").forEach((skill) => {
      if (skill) {
        cleanedData = [...cleanedData, { name: skill.trim() }];
      }
    });
  }
  return cleanedData;
}

const cancelSources = new Map([]);
const cancelLastRequest = (requestId) => {
  const token = cancelSources.get(requestId);
  if (token) {
    token.cancel("aborted");
  }
};

const getCancelToken = (requestId) => {
  cancelLastRequest(requestId);
  const nextCancelSource = axios.CancelToken.source();
  cancelSources.set(requestId, nextCancelSource);
  return nextCancelSource.token;
};

export { cancelLastRequest, getCancelToken };
