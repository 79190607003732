/* eslint-disable no-else-return */
/* eslint-disable no-unreachable */
import React from "react";

import InvoiceForm from "../../components/InvoiceForm";
import {
  INVOICE_TYPE_COMMITMENT,
  INVOICE_TYPE_CREDIT_NOTE,
  INVOICE_TYPE_FINAL,
  INVOICE_TYPE_PURCHASE,
  INVOICE_TYPE_SALE,
  INVOICE_TYPES,
} from "../api";
import { cleanInvoiceFormInput } from ".";
import { createModal } from "../modals";
import { createInvoice, createInvoiceBatch } from "../../redux/actions/InvoiceActions";
import store from "../../redux/store";
import AddPaymentForm from "../../components/AddPaymentForm";

const onCreateInvoice = (type, props) => {
  const { project, data: d, errors, refreshList } = props;

  const invoiceType =
    type === INVOICE_TYPE_PURCHASE
      ? `freelancer ${INVOICE_TYPES[type].toLowerCase()}`
      : `client ${INVOICE_TYPES[type].toLowerCase()}`;

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";
  const okayBtn =
    scope === "sideBarPayment"
      ? "Create invoice"
      : `Save ${type === INVOICE_TYPE_PURCHASE ? "Payout" : "Payment"}`;

  const modal = createModal({
    header: null,
    body: (
      <InvoiceForm
        id={`${type === INVOICE_TYPE_PURCHASE ? "payout" : "payment"}-form`}
        invoice={cleanInvoiceFormInput({
          type,
          commitment_amount:
            type === INVOICE_TYPE_FINAL && project?.commitment_amount_balance
              ? project.commitment_amount_balance
              : 0,
          ...(d || {}),
        })}
        errors={errors}
      />
    ),
    options: {
      title: project ? `Add ${INVOICE_TYPES[type]}` : `Create ${invoiceType}`,
      className: "modal-payments modal-large",
      ok: okayBtn,
      form: {
        type: "submit",
        form: `${type === INVOICE_TYPE_PURCHASE ? "payout" : "payment"}-form`,
      },
      hideActions: false,
    },
    proceed: (data) => {
      modal.setIsLoading(true);
      const resetModalLoading = () => modal.setIsLoading(false);
      // Use project if action initiated from project or if as a result of project search
      const proj = project || data?.project;

      if (
        [
          INVOICE_TYPE_SALE,
          INVOICE_TYPE_CREDIT_NOTE,
          INVOICE_TYPE_COMMITMENT,
          INVOICE_TYPE_FINAL,
        ].includes(data.type)
      ) {
        let line_items = Object.keys(data.payouts).map((key) => data.payouts[key]);

        if (data.type === INVOICE_TYPE_COMMITMENT) {
          line_items = [
            {
              amount: parseFloat(data.amount),
              description: "Commitment Payment",
              line_item_type: "Commitment Payment",
            },
          ];
        }

        if (data.type === INVOICE_TYPE_CREDIT_NOTE) {
          line_items = [
            {
              amount: parseFloat(data.amount),
              description: "Credit Note",
              line_item_type: "Credit Note",
            },
          ];
        }

        const payload = {
          ...data,
          milestone: data.milestone ? { id: data.milestone.id } : null,
          user: {
            id: proj?.owner ? proj?.owner.id : proj?.user?.id,
          },
          project: { id: proj.id },
          line_items,
          amount: 0,
          scope,
        };

        store.dispatch(
          createInvoice(payload, {
            successCb: () => modal.close(),
            failureCb: resetModalLoading,
          }),
        );
      } else if (data.invoice.type === INVOICE_TYPE_PURCHASE) {
        const cleanData = [];
        const { invoice, payouts } = data;

        if (payouts && invoice) {
          delete invoice.ref_invoice;

          const userInvoices = {};

          Object.keys(payouts).forEach((idx) => {
            const payout = payouts[idx];
            if (payout.user && payout.amount) {
              const userId = payout.user.id;

              // If no invoice for the user exists, create a new one
              if (!userInvoices[userId]) {
                userInvoices[userId] = {
                  ...invoice,
                  milestone: invoice.milestone ? { id: invoice.milestone.id } : null,
                  user: { id: userId },
                  project: { id: data.project?.id },
                  amount: 0,
                  line_items: [], // Initialize line_items array
                };
              }

              // Add line_items details to the user's invoice
              userInvoices[userId].line_items.push({
                line_item_type: payout.line_item_type,
                description: payout.description,
                amount: parseFloat(payout.amount),
              });
            }
          });

          // eslint-disable-next-line no-unreachable
          Object.keys(userInvoices).forEach((userId) => {
            cleanData.push(userInvoices[userId]);
          });
        }

        // eslint-disable-next-line no-unreachable
        if (cleanData.length) {
          store.dispatch(
            createInvoiceBatch(cleanData, {
              successCb: () => {
                modal.close();
                refreshList();
              },
              failureCb: resetModalLoading,
            }),
          );
        }
      }
    },
  });

  modal.open();
};

const onCreatePayment = (type, props) => {
  const { project, errors } = props;

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";

  const okayBtn = scope === "sideBarPayment" ? "Create invoice" : `Save Payment`;

  const modal = createModal({
    header: null,
    body: (
      <AddPaymentForm
        id="payment-form"
        invoice={cleanInvoiceFormInput({
          type,
        })}
        errors={errors}
      />
    ),
    options: {
      title: project ? `Add Payment` : `Create Client Payment`,
      className: "modal-payments modal-xlarge",
      ok: okayBtn,
      form: {
        type: "submit",
        form: "payment-form",
      },
      hideActions: false,
    },
    proceed: (data) => {
      modal.setIsLoading(true);
      const resetModalLoading = () => modal.setIsLoading(false);
      const proj = project || data?.project;

      const line_items = Object.keys(data.payouts).map((key) => {
        const devData = data.payouts[key].developer;
        const developer = typeof devData === "number" ? devData : devData.id;

        return {
          ...data.payouts[key],
          developer,
        };
      });

      const payload = {
        ...data,
        milestone: data.milestone ? { id: data.milestone.id } : null,
        user: {
          id: proj?.owner ? proj?.owner.id : proj?.user?.id,
        },
        project: { id: proj.id },
        line_items,
        amount: 0,
        scope,
      };

      delete payload.ref_invoice;

      store.dispatch(
        createInvoice(payload, {
          successCb: () => modal.close(),
          failureCb: resetModalLoading,
        }),
      );
    },
  });

  modal.open();
};

export { onCreateInvoice, onCreatePayment };
