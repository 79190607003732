/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CATEGORIES, EVENTS } from "../../../analytics/events";
import { trackEvent } from "../../../analytics/segment";
/* -------------------------- Internal dependencies ------------------------- */
import InvoiceHistory from "../../../components/InvoiceHistory";
import ReactTable from "../../../components/ReactTable";
import SummaryPlaceholder from "../../../components/SummaryPlaceholder/SummaryPlaceholder";
import useDropdownPopper from "../../../hooks/useDropdownPopper";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import {
  INVOICE_TYPE_COMMITMENT,
  INVOICE_TYPE_CREDIT_NOTE,
  INVOICE_TYPE_FINAL,
  INVOICE_TYPE_SALE,
} from "../../../utils/api";
import {
  canCreateClientInvoices,
  canCreateCommitmentInvoice,
  canCreateCreditNoteInvoice,
  canCreateFinalInvoice,
  canCreateSaleInvoice,
  isCMOrCSOAndHasProjectAcess,
} from "../../../utils/auth";
import { tableData } from "../../../utils/invoiceUtils";
import PaymentFilters from "./components/PaymentFilters";
import { useInvoices } from "../../../context/InvoiceContext";
import { columnFilter, generateColumnsFromArray } from "../../../utils/helpers";
import { onCreateInvoice, onCreatePayment } from "../../../utils/invoiceUtils/onCreateInvoice";
import { getTableColumns } from "../../../utils/invoiceUtils/tableColumns";
import { getTableDisplayValue } from "../../../utils/invoiceUtils/paymentTableDisplay";

/* --------------------------- Styles dependencies -------------------------- */
import { batchPartialInvoices } from "../../../utils/invoiceUtils/batchInvoices";

const Payments = (props) => {
  const {
    data: invoices,
    count,
    onLoadMore,
    currentPage,
    project,
    setcreateAction,
    pageSize,
  } = props;
  const [open, setopen] = useState(null);
  const [checked, setChecked] = useState([]);
  const { open: openSideBar } = useRightNav();
  const { paymentColumns, paymentState, setPaymentColumns } = useInvoices();

  const batchInvoices = useMemo(() => {
    return batchPartialInvoices(invoices);
  }, [invoices]);

  const { styles, attributes, setPopperElement, setReferenceElement, dropDownPortal } =
    useDropdownPopper();
  const { errors, isMakingRequest } = useSelector(({ Invoice }) => Invoice);

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";
  const allIDs = invoices.filter(({ paid }) => !paid);

  const checkAll = () => {
    return setChecked((v) => {
      if (v.length === allIDs.length) {
        return [];
      }
      return allIDs;
    });
  };

  const checkItem = (item) => {
    let newArr = [];

    const hasItem = checked.filter((a) => a.id === item.id).length > 0;

    if (!hasItem) {
      newArr = [...checked, item];
    } else {
      newArr = checked.filter((a) => a.id !== item.id);
    }
    setChecked(newArr);
  };

  /* istanbul ignore next */
  const toggleAll = (state) => {
    const checkboxes = document.querySelectorAll("[name=check-it]");
    const checkedBoxMapped = Array.from(checkboxes)
      .filter((checkbox) => {
        const found = invoices.find((fp) => fp.id === +checkbox.id);
        return found && !found.paid;
      })
      .map((checkbox) => {
        checkbox.checked = state;
        const found = invoices.find((fp) => fp.id === +checkbox.id);
        return found;
      });

    if (state) {
      setChecked(checkedBoxMapped);
    } else {
      setChecked([]);
    }
  };

  const toggleAction = (invoiceId) => {
    setopen(open === invoiceId ? null : invoiceId);
  };

  const isChecked = checked.length !== 0 && checked.length === allIDs.length;

  const tableColumnsRaw = getTableColumns(
    paymentState.invoiceStatus[0],
    project,
    isChecked,
    checkAll,
  );

  const columnFilterFn = columnFilter(paymentColumns);
  const tableColumns = tableColumnsRaw.filter(columnFilterFn);
  const tableColumnsArr = generateColumnsFromArray(tableColumnsRaw);

  useEffect(() => {
    setPaymentColumns(tableColumnsArr);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length && errors?.create?.data) {
      if (errors.create.data.type === INVOICE_TYPE_SALE) {
        onCreatePayment(INVOICE_TYPE_SALE, {
          ...props,
          data: errors.create.data,
          errors: errors.create,
        });
      } else {
        onCreateInvoice(errors.create.data.type, {
          ...props,
          data: errors.create.data,
          errors: errors.create,
        });
      }
    }
  }, [errors]);

  const allowed = canCreateClientInvoices();

  useEffect(() => {
    if (setcreateAction)
      setcreateAction({
        // If on dashboard any CSO can initiate creation of payment
        ...(!project || (isCMOrCSOAndHasProjectAcess(project) && !project?.archived && allowed)
          ? {
              visibility: true,
              add: [
                canCreateSaleInvoice()
                  ? {
                      title: "Add New Payment",
                      action: () => onCreatePayment(INVOICE_TYPE_SALE, props),
                    }
                  : {},

                canCreateCreditNoteInvoice()
                  ? {
                      title: "Add Credit Note",
                      action: () => onCreateInvoice(INVOICE_TYPE_CREDIT_NOTE, props),
                    }
                  : {},
                canCreateCommitmentInvoice()
                  ? {
                      title: "Add Commitment",
                      action: () => onCreateInvoice(INVOICE_TYPE_COMMITMENT, props),
                    }
                  : {},
                canCreateFinalInvoice()
                  ? {
                      title: "Add Final Invoice",
                      action: () => onCreateInvoice(INVOICE_TYPE_FINAL, props),
                    }
                  : {},
              ],
            }
          : { visibility: false }),
      });
  }, [setcreateAction, project, allowed]);

  const handleInvoiceHistory = (invoice) => {
    trackEvent(EVENTS.view_invoice_history, {
      invoice_id: invoice.id,
      invoice_title: invoice.full_title,
      event_category: CATEGORIES.payments,
    });
    openSideBar(<InvoiceHistory selected_invoice={invoice} type="payment" />, { width: "450px" });
  };

  // Create settings object for getTableDisplayValue
  const tableDisplaySettings = {
    setReferenceElement,
    setPopperElement,
    styles,
    attributes,
    dropDownPortal,
    checkItem,
    project,
    scope,
    checked,
    handleInvoiceHistory,
  };

  return (
    <div className="section mt-3">
      <PaymentFilters checked={checked} toggleAll={toggleAll} project={project} />

      {!isMakingRequest?.list && invoices.length === 0 ? (
        <SummaryPlaceholder
          className="page-filters-pay-summary"
          description="No payments have been created yet"
        />
      ) : (
        <ReactTable
          scope="payment"
          colDeps={[JSON.stringify(checked), JSON.stringify(paymentColumns)]}
          tableData={tableData(batchInvoices)}
          selectedRows={checked}
          tableColumns={tableColumns}
          currentPage={currentPage}
          count={count}
          getTableDisplayValue={(cell) =>
            getTableDisplayValue(cell, props, toggleAction, open, tableDisplaySettings)
          }
          loadPage={onLoadMore}
          pageSize={pageSize}
          isLoading={isMakingRequest?.list}
          noDataMessage="No payments have been created yet"
        />
      )}
    </div>
  );
};

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  filter: PropTypes.string,
  project: PropTypes.shape({}),
  isSaving: PropTypes.shape({}),
  count: PropTypes.number,
  onLoadMore: PropTypes.func,
  currentPage: PropTypes.number,
  setcreateAction: PropTypes.func,
};

Payments.propTypes = proptypes;
Payments.defaultProps = {
  project: null,
};

export default Payments;
